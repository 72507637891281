import React from 'react';
import './GreetHeader.css';
function GreetHeader({greetText}) {
  return (
    <div className='greet'>
      <h1 className='welcome-text'>{greetText}</h1>
      <p className='day-date'>
          {
              new Date().toLocaleDateString('en-US', {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
              })
          }
      </p>
    </div>
  )
}

export default GreetHeader;