import { useContext } from "react";
import { CommunityChatContext } from "./ComunityChatContext";
import authContext from "../../Context/Auth/AuthContext";

export const CommunityChatHook = () => {
    const [state, setState] = useContext(CommunityChatContext);
    const { userData, apiCall } = useContext(authContext);
    const socket_URL = `${process.env.REACT_APP_BACKEND_URL.replace('http', 'ws')}/ws/group_chat`;

    const updateState = async (updatedObj) => {
        await setState((stateObj) => ({ ...stateObj, ...updatedObj }));
    };
    
    const fetchCommunitiesList = async () => {
        await updateState({ isCommunitiesLoading: true });
        const { res, err } = await apiCall('/api/groups', 'get');
        
        if(err) {
            
            return;
        }

        await updateState({
            isCommunitiesLoading: false,
            communities: res,
        })
    }

    const handleResize = () => {
        try {
          
          if (state.isMiniWindow) {
            if (state.isCommunityChatOpen) {
              document.getElementById('communities_container_div').style.display = 'none';
              document.getElementById('chat_content_div').style.display = 'block';
            }
            else {
              document.getElementById('communities_container_div').style.display = 'block';
              document.getElementById('chat_content_div').style.display = 'none';
            }
            document.getElementById('communities_container_div').style.width = '100%';
            document.getElementById('chat_content_div').style.width = '100%';
          }
          else {
            document.getElementById('communities_container_div').style.width = '30%';
            document.getElementById('chat_content_div').style.width = '70%';
            // document.getElementById('chat_content_div').style.display = 'block';
          }
        }
        catch (error) {
          
        }
      }

    const handleSocketOpen = async (event) => {
        await setState((stateObj) => {
            return {
                ...stateObj,
                 openedCommunityChat: {
                  ...stateObj.openedCommunityChat,
                  isMessagesLoading: false,
                },
            }
        });
    }

    const handleSocketClose = (event) => {
        
    }

    const handleMessageSend = async (messageContent) => {
        
        if(state?.openedCommunityChat?.socket?.readyState === 0) { 
            alert('unable to send message, please reload and try again');  
            return;
        }
        await setState(stateObj => {
            return {
                ...stateObj,
                openedCommunityChat: {
                    ...stateObj.openedCommunityChat,
                    messages: [...stateObj.openedCommunityChat.messages, {
                        content: messageContent,
                        sender: userData,
                        timestamp: `T${new Date().toString().substr(16, 8)}`,
                    }],
                }
            }
        })
        await state.openedCommunityChat.socket.send(JSON.stringify({
            content: messageContent,
            sender: userData.user_id,
        }))
        
    }

    const handleCommunityChatOpen = async (community) => {
        // fetches the messages of user 
        if (state.isCommunityChatOpen) {
            if (state.openedCommunityChat.community_id === community.community_id) {
                return;
            } else {
                await updateState({
                    isCommunityChatOpen: false, 
                    openedCommunityChat: { 
                        community: null, 
                        messages: null,
                        isMessagesLoading: false,
                    }
                });
                state.openedCommunityChat?.socket?.close();
            }
        }
        
        await updateState({
            isCommunityChatOpen: true, 
            openedCommunityChat: { 
                community: community, 
                messages: null, 
                isMessagesLoading: true,
                socket: null,
            }
        });
        
        const { res, err } = await apiCall(`/api/group_chats/${community.community_id}`, 'get');
        
        let socket = new WebSocket(`${socket_URL}/${community.community_id}/`);

        socket.addEventListener("open", handleSocketOpen);
        socket.addEventListener("close", handleSocketClose);
        socket.addEventListener("message", handleSocketMessage);

        await updateState({
            openedCommunityChat: {
                community: community,
                messages: res,
                isMessagesLoading: true,
                socket,
            }
        });
        
        // handleResize();
    }

    const handleCommunityChatClose = async () => {
        // closes the messages of community
        state?.openedCommunityChat?.socket?.close();

        await updateState({
            isCommunityChatOpen: false,
            openedCommunityChat: {
                community: null,
                messages: null,
                isMessagesLoading: false,
                socket: null,
            }
        })
    }

    const handleSocketMessage = async (event) => {
        let newMessage = JSON.parse(event.data);
        if (newMessage.sender.user_id === userData.user_id) return;
        await setState(stateObj => {
            let isMessageExist = stateObj.openedCommunityChat.messages?.find(message => newMessage.id === message.id);
            if(isMessageExist)      return stateObj;

            return {
                ...stateObj,
                openedCommunityChat: {
                    ...stateObj.openedCommunityChat,
                    messages: [...stateObj.openedCommunityChat.messages, newMessage],
                }
            }
        })
        
    }

    return {
        state: state,
        fetchCommunitiesList,
        updateState,
        handleResize,
        handleCommunityChatOpen,
        handleCommunityChatClose,

        handleMessageSend,
    }
}
