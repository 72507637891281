import './CommunityChat.css'
import CommunityChatBody from "./CommunityChatBody";
import { CommunityChatProvider } from "./ComunityChatContext";

export default function Chat() {
    return (
        <CommunityChatProvider>
            <CommunityChatBody />
        </CommunityChatProvider>
    )
}
