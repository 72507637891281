import React from 'react';
import Footer from '../components/Footer/Footer';
import HomeNav from '../components/HomeNav/HomeNav';
import CommunityBox from '../components/CommunityBox/CommunityBox';

function Community() {
  return (
    <>
       <HomeNav/>
       <CommunityBox/>
       <Footer/>
    </>
  )
}

export default Community
