import React, { useEffect, useRef } from "react";
const Message = ({ message, user }) => {
  let shortTime = message.timestamp?.split("T")[1].split(".")[0].slice(0, -3);
  let messageType =
    (message.receiver === user.user_id ? "sent" : "received") + "-message";

  return (
    <div className={"message " + messageType}>
      <p className="chat-message-text">{message.content}</p>
      <span className="chat-message-time">{shortTime} </span>
    </div>
  );
};

export const Messages = ({ user, messages, isMessagesLoading }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages, isMessagesLoading]);

  const MessagesLoading = () => (
    <div className="chat-content-info">
      <h4>Loading...</h4>
    </div>
  );

  const NoMessages = () => (
    <div className="chat-content-empty">
      <h4>No messages yet</h4>
    </div>
  );

  return (
    <div className="messages">
      {isMessagesLoading
        ? <MessagesLoading />
        : !messages || (messages?.length === 0) 
          ? <NoMessages />
          : messages.map((message) => (<Message key={message.id} user={user} message={message} />))}
      <div ref={messagesEndRef} />
    </div>
  );
};
