import React from "react";
import Marquee from "react-fast-marquee";
import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";
import image4 from "./4.png";


export default function Creators() {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image1,
    image2,
    image3,
    image4,
  ];
  return (
    <div className="creators" style={{
      marginTop:"3rem",
      marginLeft:"auto",
      marginRight:"auto",
      marginBottom:"1.2rem",
      width:"85vw",
      padding:"20px",
      // boxShadow: "#b8e0f6 0px 7px 29px 0px",
      transition: "box-shadow 0.5s ease-in-out",
      
    }}>
     <h1 style={{
      fontSize:"1.5rem",
     }}>Members from:</h1>

      <Marquee
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
          padding: "20px",
        }}
        speed={90}
        gradientWidth={0}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="logo"
            style={{
              width: "70%",
              height: "70%",
              margin: "auto",
              padding: "18px",
            }}
          />
        ))}
      </Marquee>
    </div>

  );
}
