import {React,useState,useContext,useEffect} from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner/Spinner';
import imglogin from './loginimg.png';
import view from './view-hide.png';
import './Signin.css';
import elbg from './el-bg.png';
import axios from 'axios';
import AuthContext from '../Context/Auth/AuthContext';


function Signin() {
  const navigate = useNavigate();
//   const {isAuthenticated} = useContext(AuthContext);
//   const [isAuth, setIsAuth] = useState(false);
//   useEffect(() => {
//     const checkAuth = async () => {
//       const authStatus = await isAuthenticated();
//       setIsAuth(authStatus);
//     };
//     checkAuth();
//     if (isAuth) {
//         navigate('/dashboard');
//       }
//   }, [isAuthenticated,navigate]);

   useEffect(() =>{   
    const token = localStorage.getItem('token');
    if(token){
        navigate('/dashboard');
    }
    },[navigate]);


    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setloading] = useState(false);
    const movesignup = () => {
        window.location.href = '/signup';
    }
    const [credentials, setcredentials] = useState({ username: "", password: "" })
    const onchange = (e) => {
        setcredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const backendurl = process.env.REACT_APP_BACKEND_URL;

    const handleSignin = async (e) => {
        e.preventDefault();
        setloading(true);
        await axios.post(`${backendurl}/api/token/`, credentials).then(res => {
        if (res.status === 200) {
            localStorage.setItem('token', res.data.access);
            window.location.href = '/dashboard';
        }
        }).catch(err => {
            console.log(err);
            alert('Invalid Credentials');
        });
        setloading(false);
    }

  return (
    <>
       <HomeNav/>
       <div className='signin-bx-1 fl-r'>
            <div className="signin-bx-2">
                <div className="signin-txt-bx ">
                    <div className="sign-in-txt-1">  Oh? <br /> Hello there</div>
                    <div className="sign-in-txt-2"> New user? Please <b onClick={movesignup}>Register </b></div>
                </div>
                <div className="signin-img1"> <img alt=''  src={imglogin} /></div>
            </div>
            <div className="signin-bx-3">
                <div className="signin-signup">
                    <form action="" className="sign-in-form">
                        <div className="input-field">
                            <input type="text" name="username" value={credentials.email} placeholder="Enter Username" onChange={onchange} style={{
                                width: '19rem',
                                background: "#F5F5F5",
                            }}/>
                        </div>
                        <div className="input-field">
                            <input type={passwordShown ? "text" : "password"} value={credentials.password} name="password" onChange={onchange} placeholder="Password" /><span><img className='viewpass' onClick={togglePasswordVisiblity} alt='' src={view} /></span>
                        </div>
                        <Link to="/forgot-password" class="forget-text">Forgot password? </Link>
                        {loading ? <Spinner /> : <button onClick={handleSignin}>SignIn</button>}
                    </form></div>
            </div>
        </div>
       <Footer/>
    </>
  )
}

export default Signin;
