import React from "react";
import "./pricingkmbox.css";

const Pricingkmbox = () => {
  return (
    <div className="pricing-box container">
      <div className="our_services1">
        <div className="service">
          <h4>1 on 1 Consultation</h4>
          <p>
          You will be having 1 on 1 interaction with the speakers of the events hosted. You can ask them question, to get some guidance. They are the best people to give you an understanding of our hands-on experience.
          </p>
        </div>
        <div className="service">
          <h4>Access to all Cohorts</h4>
          <p>
          You will have access to all the cohorts, that will be hosted. The average ticket price for these will be Rs. 3199 to Rs. 5999, may vary from cohort to cohort. A systemized plan will be given for a duration of 6 Weeks, that will go from basic to advanced.
          </p>
        </div>
        <div className="service">
          <h4>Paid Communities</h4>
          <p>
          These exclusive communities for our plus members will include:
            <ul>
                <li>Verified Tips for F&O and Signals for Swing trading on Stock markets & Crypto</li>
                <li>In demand Design trends and paid resources</li>
                <li>Resources like courses and other handbook material for Web3 ethusiasts along with exclusive courses to get started</li>
                <li>
                Verified profiles and leads for recently launched startups, so that the do no waste their time on BS things
                </li>
            </ul>
          </p>
        </div>
        <div className="service">
          <h4>Free Access to events</h4>
          <p>You will be co-ordinaly invited to all our offline events and workshops to network like the top 1% and stay hungry to get more oportunities!</p>
        </div>
      </div>
    </div>
  );
};

export default Pricingkmbox;