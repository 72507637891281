import React from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import PricingBox from '../components/Pricingbox/PricingBox';

function Pricing() {
  return (
    <>
        <HomeNav/>
        <PricingBox/>
        <Footer/>
      
    </>
  )
}

export default Pricing
