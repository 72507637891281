import React from 'react'
import HomeNav from '../components/HomeNav/HomeNav'
import Footer from '../components/Footer/Footer'
import Pricingkmbox from '../components/Pricingkmbox/Pricingkmbox'

function Pricingknowmore() {
  return (
    <>
      <HomeNav/>
      <Pricingkmbox/>
      <Footer/>
    </>
  )
}

export default Pricingknowmore
