import React from "react";
import Color, { Palette } from "color-thief-react";

export default function CommunityHeader({ community, onClick = null, onCommunityChatClose }) {
  
  return (
    <Palette
      style={{ zIndex: 2 }}
      src={community?.logo}
      crossOrigin="anonymous"
      format="hex"
      colorCount={2}
    >
      {(data) => {
        if (data.data) {
          const color = getTextColor(data.data[1]);
          return (
            <>
              <div
                className="d-flex myClass cursor-pointer position-relative align-items-center justify-content-center w-100 rounded-1 py-2 my-3"
                style={{
                  background: `linear-gradient(to right, ${data.data})`,
                  color: `${color}`,
                  padding: "0px 3rem 0 2rem"
                }}
                onClick={onClick}
              >
                {/* <div className='receiver_details_container'>
                  <div className='back_button' onClick={handleChatClose}>
                    <i className='fa fa-arrow-left'></i>
                  </div>
                  <div className='receiver_details'>
                    <div className='receiver_image'>
                      <img src={community.logo} alt='user' />
                    </div>
                    <h4 className='receiver_details_name'>
                      {community.name}
                    </h4>
                  </div>
                </div> */}

                <div className="community_chat_header d-flex flex-row align-items-center">
                  <div className="community_chat_header__actions">
                    <div className="community_chat_header__actions__back" onClick={onCommunityChatClose}>
                      <i className="fa fa-arrow-left"></i>
                    </div>
                  </div>
                  <img className="community_chat_header__details_logo" src={community?.logo} />
                  <div className="community_chat_header__details">
                    <div className="community_chat_header__details__name">
                      {community.name}
                    </div>
                    <div className="community_chat_header__details__members">
                      {community.members_count} members
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      }}
    </Palette>
  );
};

function calculateBrightness(r, g, b) {
  return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
}

function getTextColor(backgroundColor) {
  const rgb = hexToRgb(backgroundColor);
  const brightness = calculateBrightness(rgb.r, rgb.g, rgb.b);

  return brightness > 130 ? "#000000" : "#FFFFFF"; // Dark text for light backgrounds, and vice versa
}

// Helper function to convert a hex color value to RGB
function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};