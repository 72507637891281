import {React,useState,useEffect} from 'react';
import './HomeNav.css';
import navlogo from './navlogo.png';

function HomeNav() {
    const [showlog, setShowlog] = useState(false);
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken)
          setShowlog(true);
      }, []);
    const [showNavbar, setShowNavbar] = useState(false);
    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar);
    };
    return (
      <div>
      <nav className="navbar1">
        <div className="nav-container">
          <div className='left'>
            <div className="logo">
            <img src={navlogo} alt="logo" className='logo-img' style={{
              marginTop:"-8px"
            }}/>
                <a href="/">
                    <h1 className='logo-text'>MancMint</h1>
                </a>
            </div>
          </div>
          <div className='right'>
                <div className="menu-icon" onClick={handleShowNavbar}>
                      <Hamburger />
                </div>
                <div className={`nav-elements  ${showNavbar && "active"}`} style={{
                   
                }}>
                  <ul>
                  <li> <a className= {window.location.pathname === "/" ? "active" : ""} href='/'> Home</a></li>
                  <li> <a className={window.location.pathname === "/blog" ? "active" : ""} href='/blog'> Blog</a></li>
                  <li> <a className={window.location.pathname === "/community" ? "active" : ""} href='/community'> Community</a></li>
                  <li> <a className={window.location.pathname === "/pricing" ? "active" : window.location.pathname === "/pricing-knowmore" ? "active": ""}href='/pricing'> Pricing</a></li>
                  <li className='mobile-btns'>
                  {!showlog ? <button className='nav-btn' onClick={() => window.location.href='/signin'}>Login/SignUp</button> :
                    <button className='nav-btn' onClick={() => window.location.href='/dashboard'}>Dashboard</button>}
                    {/* <button className='nav-btn'> <i class="fa-solid fa-moon"></i></button> */}
                  </li>
                  </ul>
                </div>
                <div className="nav-btns">
                  {!showlog ? <button className='nav-btn'
                    onClick={() => window.location.href='/signin'}
                  >Login/SignUp</button> :
                      <button className='nav-btn' onClick={() => window.location.href='/dashboard'}>Dashboard</button>}
                      {/* <button className='nav-btn-1'> <i class="fa-solid fa-moon"></i></button> */}
                </div>
           </div>
        </div>
      </nav>
      </div>
    );
}

const Hamburger = () => (
    <i class="fas fa-bars"></i>
  );


export default HomeNav
