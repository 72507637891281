import CommunityChatMessage from "./CommunityChatMessage";
import CommunityHeader from "./CommunityHeader";

import ChatInputBox from "../ChatInputBox";
import { useContext, useEffect, useRef } from "react";
import authContext from "../../Context/Auth/AuthContext";

const MessageLoading = () => (
  <div className='chat_content_empty'>
    <h4>Loading...</h4>
  </div>
);
const NoMessages = () => (
  <div className='chat_content_empty'>
    <h4>No messages yet</h4>
  </div>
);

export default function CommunityChatBox({ openedCommunityChat, onCommunityChatClose, handleMessageSend }) {
  /* openedCommunityChat: {
        community: null,
        messages: null,
        isMessagesLoading: false,
        socket: null,
    }, */
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { community, messages, isMessagesLoading } = openedCommunityChat;
  const { userData } = useContext(authContext);

  useEffect(scrollToBottom, [messages, isMessagesLoading]);

  return (
    <div className='chat_content' id='chat_content_div'>
      <CommunityHeader community={community} onCommunityChatClose={onCommunityChatClose} />
      <div className='messages'>
        {isMessagesLoading
          ? <MessageLoading /> 
          : !messages || (messages?.length === 0)
            ? <NoMessages />
            : messages.map((message, index) => <CommunityChatMessage key={index} user={userData} message={message} />)}
        <div ref={messagesEndRef} />
      </div>  
      <ChatInputBox handleMessageSend={handleMessageSend}/>
      <div />
    </div>
  );
}