import React from 'react';
import './Faqs.css';
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "What is MancMint?",
            content: `Tomorrows Commune for like-minded people. You get to Earn, Learn and Network. Start  upscaling your career, by signing up today!`,
        },
        {
            title: "How can I upskill?",
            content:
              `You can join various communities and start networking will the community members, and learn something from them. To up-skill, you have to never stop learning.`,
        },
        {
            title: `How can I start earning?`,
            content: `To start earning, you first need to learn a skill. Then start interacting with people of your niche and collaborate, to find paying clients. This will be your first step. After making an attractive portfolio, you can start applying for jobs from the Hire section in MancMint.`,
        },
    ],
  };
  
  const styles = {
    bgColor: "transparent",
    rowTitleColor: "#000000",
    rowContentColor: '#000000',
    rowContentTextSize: '0.9rem',
    arrowColor: "#000000",
    textAlign: "left",
  };
  
  const config = {
     animate: true,
     arrowIcon: "+",
     tabFocus: true,
     openOnload: true,
  };

function Faqs() {
  return (
    <div className='container faqs' style={{
        marginTop: '2rem',
        marginBottom: '4rem',
        fontFamily: 'Poppins, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <div className='row'>
            <div className='col-md-7 col-lg-7 brr'>
                <h2 style={{
                    marginBottom: '1rem',
                    fontSize: '2.1rem',
                    fontWeight: '800',
                    lineHeight: '1.2',
                    color: '#000',
                    fontFamily: 'Poppins, sans-serif',
                }}>FAQ</h2>
                 <Faq
                        data={data}
                        styles={styles}
                        config={config}
                        openOnload={true}
                        
                  />
            </div>
            <div className='col-md-5 col-lg-5 faq-left'>
                <h3 style={{
                    fontSize: '2rem',
                    fontWeight: '800',
                    lineHeight: '1.2',
                    color: '#000',
                    fontFamily: 'Poppins, sans-serif',
                    lineHeight: '1.5',
                
                }}>
                    Have any more <br/>questions?
                </h3>
                <h4 style={{
                    fontSize: '1.4rem',
                    fontWeight: '400',
                    lineHeight: '1.2',
                    color: '#000',
                    fontFamily: 'Poppins, sans-serif',
                    lineHeight: '1.5',
                    marginTop: '1rem',
                }}>
                Chat with us on our whatsapp <br/> and clear all your doubts
                </h4>
                <button className='wp-button' onClick={
                    () => {
                        window.open('https://wa.me/919966236803', '_blank')
                    }
                }>
                        <i class="bi bi-whatsapp"/> Let's Chat
                </button>
                <h6>
                *We are available from Mon- Sat, 9AM - 8PM
                </h6>
            </div>
            
        </div>
    </div>
  )
}

export default Faqs
