import React from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';

function Cohort() {
  return (
    <div>
        <HomeNav/>
           <div className='container'>
                <div className='c1' style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    flexDirection:"column",
                    height:"50vh",
                }}>
                     <h2>Coming Soon</h2>
                </div>
           </div>
        <Footer/>
    </div>
  )
}

export default Cohort
