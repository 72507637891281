import {React,useState,useContext,useEffect} from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner/Spinner';
import imglogin from './loginimg.png';
import view from './view-hide.png';
import './Signin.css';
import elbg from './el-bg.png';
import axios from 'axios';
import AuthContext from '../Context/Auth/AuthContext';
import { useParams } from 'react-router-dom';


function ForgotPass() {
  const navigate = useNavigate();
  let { resetToken } = useParams();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);


    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setloading] = useState(false);
    const movesignup = () => {
        navigate('/signin');
    }
    const [credentials, setcredentials] = useState({ email: ""})
    const onchange = (e) => {
        setcredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const backendurl = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = {
            email: credentials.email,
        }

        await axios.post(`${backendurl}/api/password_reset/`, payload).then(res => {
            if (res.status === 200) {
                alert("Password reset link sent to your email");
                window.location.href = '/signin';
            }
            if(res.status === 400){
                alert("Invalid email ",res.data.email);
            }
        }).catch(err => {
            console.log(err.response.data.email);
            alert(err.response.data.email[0]);
        }
        );
        setloading(false);
    }

  return (
    <>
       <HomeNav/>
       <div className='signin-bx-1 fl-r'>
            <div className="signin-bx-2">
                <div className="signin-txt-bx ">
                    <div className="sign-in-txt-1">  Oh? <br /> Hello there</div>
                    <div className="sign-in-txt-2"> Remember your Password? <br/> Please  go back to <b onClick={movesignup}>Signin </b></div>
                </div>
                <div className="signin-img1"> <img alt=''  src={imglogin} /></div>
            </div>
            <div className="signin-bx-3">
                <div className="signin-signup">
                    <h6>Enter your Email:</h6>
                    <form action="" className="sign-in-form">
                        <div className="input-field">
                            <input type="email" name="email" value={credentials.password} placeholder="Enter Email" onChange={onchange} style={{
                                width: '19rem',
                                backgroundColor: '#f5f5f5',
                            }}/>
                        </div>
                        {loading ? <Spinner /> : <button onClick={handleSubmit}>Submit</button>}
                    </form></div>
            </div>
        </div>
       <Footer/>
    </>
  )
}

export default ForgotPass;
