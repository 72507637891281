import { React, useState } from "react";
import "./SearchComp.css";

function HireSearchComp() {
  const [searchVal, setSearchVal] = useState("");

  const handleInput = (e) => {
    setSearchVal(e.target.value);
  };
  
  return (
    <>
      <div className="container">
        <div className="input-wrap">
          <button className="search-btn">
            <i className="fas fa-search" />
          </button>
          <input
            onChange={handleInput}
            value={searchVal}
            type="text"
            name="product-search"
            id="product-search"
            placeholder="Search"
          />
        </div>
      </div>
    </>
  );
}

export default HireSearchComp;
