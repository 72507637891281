import { ChatListItem } from "./ChatListItem";
import { ThreeDots } from 'react-loader-spinner';

export default function ChatListContainer({state, onChatOpen}) {
    const { unreadChatsCount, chatsList, isChatsListLoading } = state;

    return (
        <div 
          className='chats-container'
          id='chats-container-div'
        >
            <div className='chats-title' >
                <h3>
                    <b>Messages</b>
                    {unreadChatsCount > 0 &&
                    <span className='unread-chats-count'> ({unreadChatsCount}) </span>}
                </h3>
            </div>
            <div className='list-of-chats-title'>
              <h5>All Messages</h5>
            </div>
            <div className='list-of-chats'>
              <div className='chats-list'>
                {isChatsListLoading
                    ? (<div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                      <ThreeDots
                        visible={true}
                        height="60"
                        width="60"
                        color="#1594D4"
                        radius="5"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>)
                    : chatsList.length === 0
                      ? <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "59vh",
                            padding: "0.5rem 1rem",
                          }}
                        >
                          No chats availabe
                        </span>
                      : chatsList.map((user) => <ChatListItem key={user.user_id} user={user} onChatOpen={() => onChatOpen(user)} />)
                }
              </div>
            </div>
        </div>
    )
}
