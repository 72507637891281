import { useState } from "react";
import { Button, Form } from "react-bootstrap";

export function SkillTestForm({ skills, apiCall, closeModal}) {
  const [skillTestQuestions, setSkillTestQuestions] = useState(null);
	const [submissionStatus, setSubmissionStatus] = useState("not-submitted");
	let skillTestAnswers = {};
 
   const getSkillTestQuestions = async (event) => {
		let skill_id = event.target.value;
		if(skill_id === 0)	return;

		setSkillTestQuestions([]);
    const { res, err } = await apiCall(`/api/skill_test/questions/?skill_id=${skill_id}`, "get");
	if (res.length === 0) alert("Questions not found .... Choose another skill");
    if (err) alert("something went wrong!!!");
    setSkillTestQuestions(res);
		// setSkillTestQuestions(skillTestQuestionsTemp);
  };

	const handleOptionSelection = async (question, answer) => {
		skillTestAnswers[question] = answer;
	}
  const handleSubmit = async (e) => {
		setSubmissionStatus("submitting");
		e.preventDefault();

		const body = {
			"skill_id": parseInt(e.target.firstElementChild.children[1].value),
			answers: [],
		}
		
		Object.entries(skillTestAnswers).forEach(([key, value]) => {
			body.answers.push({
				id: parseInt(key),
				answer: value
			})
		})

		const {res, err} = await apiCall('/api/skill_test/', 'post', body);

		if(err) 			alert("got error while submitting, please try again");
		if(res)				alert(`Congratulations you got score: ${res.score}`);

		closeModal();
		setSubmissionStatus("submitted");
	};

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="select-skill">
        <Form.Label>Select Skill</Form.Label>
        <Form.Select 
					aria-label="select skill" 
					id="skill_id" 
					onChange={getSkillTestQuestions}
					required
				>
					<option value="0" selected disabled>Select skill</option>
					{skills.map(skill => 
						<option 
							key={skill.id} 
							value={skill.id}
						>
							{skill.name}
						</option>
					)}
				</Form.Select>
      </Form.Group>
			{skillTestQuestions
				? skillTestQuestions?.length === 0 
					? "Loading ..."
					: <div>
							{skillTestQuestions.map(question => (
								<div key={question.id} style={{ marginTop: '1rem' }}>
									<Form.Group name={`skill_test_question_${question.id}`}>
										<Form.Label style={{ width: '100%' }}>
											{question.question}
											(<span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{question.score}</span>)
										</Form.Label>
										{question.options.map((option, idx) => (
											<Form.Check
												required
												onChange={(e) => handleOptionSelection(question.id, e.target.value)}
												name={`skill_test_question_${question.id}`}
												key={idx}
												type='radio'
												id={`skill_test_question_${question.id}`}
												label={option}
												value={option}
											/>
										))}
									</Form.Group>

								</div>)
							)}
						<Button
							style={{ marginTop: '1rem' }}
							type="submit"
						>
							{submissionStatus === "not-submitted"
								? "Submit Test"
								: submissionStatus === "submitting"
									? "Submitting ..."
									: "Thank you "
							}
						</Button>
					</div>
				: "" 
			}
    </Form>
  );
}
