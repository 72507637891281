import React from 'react';
import './About.css';
import AboutImg from './About.png';

function About() {
  return (
    <div
        className='container about'
        style={{
            marginTop: '4rem',
            marginBottom: '5rem',
            padding: '2rem',
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',

        }}
    >
        <div className='row'>
            <div className='col-lg-6'>
                <img src={AboutImg} alt='' className='about-img'/>
            </div>
            <div className='col-lg-6'>
                <h1>About Us</h1>
                <p className="about-p">
                MancMint is a commune for every kind of niche, where members can find new opportunities for work and network with like-minded people. We also offer free masterclasses held by industry experts for our members to upskill and stay updated. Additionally, we have paid cohorts/workshops that help in better understanding of skills!
                </p>
            </div>
        </div>
    </div>
  )
}

export default About
