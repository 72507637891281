import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

function Loader() {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
    }}>
        <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="#1594D4"
            radius="10"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    </div>
  )
}

export default Loader
