import profileAvatar from './profileAvatar.png';
export default function CommunityChatMessage({ user, message }) {
  let shortTime = message.timestamp?.split("T")[1].split(".")[0].slice(0, -3);
  let sent_message = message.sender.user_id === user.user_id;
  return (
    <div className={"community_chat_message" + " " + ((sent_message ? 'sent' : 'received') + '_community_chat_message')}>
      <div className="community_chat_message__header">
        <img className="community_chat_message__header__avatar" src={message.sender.profile_pic || profileAvatar } alt={message.sender.first_name} />
          <span className="community_chat_message__header__user__name">
            {sent_message
              ? "You"
              : `${message.sender.first_name} ${message.sender.last_name}`
            }
          </span>
      </div>
      <div className="community_chat_message__body">
        <p className="community_chat_message__body__text">{message.content}</p>
      </div>
      <span className="community_chat_message__footer__timestamp">
          {shortTime}
        </span>
    </div>
  );
}