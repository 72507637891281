import {React,useContext,useEffect,useRef,useState} from 'react';
import ChatListContainer from './ChatListContainer';
import { PlaceHolderChat, UserChat } from './UserChat';
import { ChatHook } from './ChatHook';

import './Chat.css'

function ChatBody({ currentlyOpenedChat = null }) {
  const {
    state,
    fetchChatsList,
    handleChatOpen,
    handleChatClose,
    handleResize,

    handleMessageSend,
  } = ChatHook();

  const intervalRef = useRef();
  const chatReloadTime = process.env.REACT_APP_CHAT_RELOAD ? process.env.REACT_APP_CHAT_RELOAD : 2;
  // fetches user chats
  useEffect(() => {
    intervalRef.current = setInterval(() => fetchChatsList(false), chatReloadTime * 60 * 1000);
    fetchChatsList();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [state.isChatOpen]);

  return (
    <div className='chat-component-parent' >
      <ChatListContainer 
        state={state}
        onChatOpen={handleChatOpen}
      />
      {state.isChatOpen 
        ? <UserChat
            opendedUserChat={state.openedUserChat}
            onChatClose={handleChatClose}
            handleMessageSend={handleMessageSend}
          />
        : <PlaceHolderChat />}

    </div>
  )
}

export default ChatBody;
