import { React, useState } from "react";
import "./SearchComp.css";
import SearchModal from "../SearchModal";

function SearchComp({ paddingLeft }) {
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setModalState] = useState(false);

  const handleInput = (e) => {
    setSearchText(e.target.value);
  };
  
  return (
    <>
      <div className="container" style={{
         paddingLeft: paddingLeft,
      }}>
        <div className="input-wrap">
          <button className="search-btn" onClick={() => setModalState(true)}>
            <i className="fas fa-search" />
          </button>
          <input
            onChange={handleInput}
            onKeyDown={(e) => {e.code === 'Enter' && setModalState(true);}}
            value={searchText}
            type="text"
            name="product-search"
            id="product-search"
            placeholder="Search"
          />
        </div>
      </div>
      <SearchModal isModelOpen={isModalOpen} setModalState={setModalState} searchText={searchText} setSearchText={setSearchText} />
    </>
  );
}

export default SearchComp;
