import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useState, useEffect } from 'react';
import WOW from 'wowjs';
import "./Hero.css";
import Dashboard from "./dashboard.png";
import bgGlare from "./bg-glare.png";
import Emoji from './imag31.png';

function Hero() {
  const texts = ['Earning', 'Learning', 'Networking'];
  const [currentText, setCurrentText] = useState('');
  useEffect(() => {
    let currentIndex = 0;
    const timeout = 1500; // Timeout in milliseconds
    const displayNextText = () => {
      setCurrentText(texts[currentIndex]);
      currentIndex = (currentIndex + 1) % texts.length;
    };
    const timer = setInterval(displayNextText, timeout);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    
      <div className="herodiv wow fadeInUp " data-wow-delay=".1s" 
        style={{
            backgroundImage: `url(${bgGlare})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "-3% 0%",
            backgroundSize: "39rem",
           
        }}        
      >
        <div className="hero-box"
        >
          <div className="hero-text-box hero-text"
          >
            <h1>{currentText}</h1>
            <h2>Just Got easier! <img className="str-img" alt="" src={Emoji} /></h2>
            <p>
                The Tomorrow’s commune for Cohort Learning, Networking, Earning and finding growth opportunities
            </p>
          </div>
          <div className="hero-img">
            <img width={"100%"} height={"100%"} src={Dashboard} alt="hero section text" 
              style={{
                borderRadius:"2rem 0rem 0rem 2rem",
                boxShadow:"-1px 4px 3px 0px #d7d7d7"
              }}
            />
          </div>
        </div>
      </div>
  );
}

export default Hero;
