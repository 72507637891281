import React from 'react';
import './UpWorkshop.css';
import cal from './cal.png';
import video from './video.png';


function UpWorkshop() {

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open('https://forms.gle/JoeJsf3CEYSVb59q8', '_blank');
  }
  
  return (
    <div className='upworkshop'>
    <div  className=''>

        <div className="workshopbox">
            <h5 style={{
                color: '#fff',
                fontWeight:'300'
            }}>
                Upcoming Workshop
            </h5>
            <h2 className='upworkshop-heading'>
                Join our Live Upcoming workshop on Stock Markets, and understanding the markets for newbies! 🚀
            </h2>
            <p style={{
                color:"#fff",
                fontWeight:'200'
            }}>
                Beginner Friendly I No experience required
            </p>
            <div className='date-time' style={{
                color:"#fff",
                fontWeight:'200',
                display:'flex',
                flexDirection:'row',
               
            }}>
                <p style={{
                    marginRight:'2rem'
                }}>
                    <img src={cal} alt="cal" className='img-fluid' style={{
                        width:"1.9rem",
                        height:"1.9rem"
                    }}/>
                    {' '}Sunday, 14th July, 2024
                </p>
                <p>
                    <img src={video} alt="video" className='img-fluid' style={{
                        width:"1.9rem",
                        height:"1.9rem"
                    }}/>
                    {' '}Online Event
                </p>
            </div>
            <div className='regbox'>
                <div id='regline'/>
                <button className='regbtn' onClick={handleSubmit}>Register for free</button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default UpWorkshop
