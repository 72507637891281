import ChatBody from "./ChatBody";
import { ChatProvider } from "./ChatContext";
import './Chat.css'

export default function Chat() {
    return (
        <ChatProvider>
            <ChatBody />
        </ChatProvider>
    )
}
