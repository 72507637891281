import React from 'react';

const SkillBox = ({ imgSrc, title, level }) => {
  return (
    <div className='b1c2c1c1 skill-box'>
      <div className='skill-box1'>
        <div>
          {imgSrc && <img src={imgSrc} alt='skill-img' className='' style={{
            width: '2.5rem',
            height: '2.5rem',
          }} />}
        </div>
        <div className='skill-box1-text'>
          <h6>{title}</h6>
          <p>{level}</p>
        </div>
        <div className='skill-box1-btn'>
          <button className='btn'><i className="fa-solid fa-chevron-right fa-2xs"></i></button>
        </div>
      </div>
    </div>
  );
};

export default SkillBox;
