import {React,useState,createContext} from 'react'
import axios from 'axios';
import AuthContext from "./AuthContext";


const AuthState = (props) => {
  
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [skills, setSkills]     = useState([]);
  const [skillScores, setSkillScores] = useState([]);
  const [previousWork, setPreviousWork] = useState([]);
  
  const [hiringData, setHiringData] = useState([]);

  const getUserData = async () => {
    const { res, err } = await apiCall('/api/profile/');
    
    if (err) {
      
      return;
    }
    setUserData(res);
    setLoading(false);
  }

  const apiCall = async (apiEndpoint, method, reqBody = {}) => {
    const url = backendURL + apiEndpoint;
    const userToken = localStorage.getItem('token');

    const obj = {
      method: method,
      data: reqBody,
      url: url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': whitelistDomains,
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const data = await axios(url, obj);
      return {res: data.data, err: null} 
    } catch (err) {
      if (err?.response?.status === 401) {
        // Remove token and refresh page
        localStorage.removeItem('token');
        window.location.href = '/signin';
      }
      return {res: null, err}
    }
  }

  const listSkills = async () => {
    setLoading(true);
    const {res, err} = await apiCall('/api/skills/');
    if (err) {
      
      return;
    }
    setSkills(res);
  }

  const getHiringData = async () => {
    setLoading(true);
    const {res, err} = await apiCall('/api/jobs/');
    if (err) {
      
      return;
    }
    setHiringData(res);
    setLoading(false);
  }

  const postJob = async (jobData) => {
    const { res, err } = await apiCall('/api/jobs/', 'post', jobData);
    if (err) {
      
      alert('Job Posting Failed');
      return;
    }
    // job posted successfully
    alert('Job Posted Successfully');

  }

  const getSkillScores = async () => {
    const {res, err} = await apiCall('/api/skill_test/result/');
    if (err) {
      
      return;
    }
    setSkillScores(res);
  }

  const getPreviousWork = async () => {
    const {res, err} = await apiCall('/api/user/skills/graph/');
    if (err) {
      
      return;
    }
    setPreviousWork(res);
  }

  return (  
    <AuthContext.Provider value={{
      userData, getUserData, setUserData, 
      loading, setLoading,
      skills, listSkills,
      skillScores, getSkillScores,
      previousWork, getPreviousWork,
      apiCall, 
      hiringData, getHiringData, postJob,
    }}>
        {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState;