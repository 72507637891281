import React            from 'react';
import Sidebar          from '../components/Sidebar/Sidebar';
// import Chatcomponent    from '../../components/chat/Chatcomponent';

import './ChatPage.css';
import GreetHeader from '../components/GreetHeader/GreetHeader';
import Chat from '../components/Chat';

function ChatPage() {
  return (
    <div className='chat-page include-sidebar'>
        <div>
            <Sidebar/>
        </div>
        <div className='chat-container'>
            <GreetHeader greetText='Personal Messages'/>
            <div className='chat-component'>
                <Chat/>
            </div>
        </div>
    </div>
  )
}

export default ChatPage;
