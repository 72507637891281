import { useState } from "react"

export default function ChatInputBox({ handleMessageSend, disableSend = false }) {
    const [message, editMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        handleMessageSend(message);
        editMessage('');

        return false;
    }
    return (
        <form className='chat-input' onSubmit={handleSubmit} style={{
            height: '3rem',
        }}>
            <input onChange={(e) => editMessage(e.target.value)} value={message} type='text' className='chat-input-message' placeholder='Type a message' style={{
                width: '90%',
                marginTop: '1rem',
            
            }}/>
            <button type="submit" disabled={disableSend}>
                <i className='fa fa-paper-plane'></i>
            </button>
        </form>
    )
}