import { Messages } from "./Messages";
import ChatInputBox from "../ChatInputBox";
import profileAvatar from './profileAvatar.png';

export const PlaceHolderChat = () => {
  return (
    <div className="chat-content" id="chat-content-div">
      <div className="chat-content-info">
        <h4>Select a chat to start messaging</h4>
      </div>
    </div>
  );
};

export const UserChat = ({ opendedUserChat, onChatClose, handleMessageSend, }) => {
  const { user, messages, isMessagesLoading } = opendedUserChat;

  return (
    <div className="chat-content" id="chat-content-div">
      <div className="receiver-details-container">
        <div className="back-button" onClick={onChatClose}>
          <i className="fa fa-arrow-left"></i>
        </div>
        <div className="receiver-details">
          <div className="receiver-image">
            <img src={user.profile_pic || profileAvatar } alt="user" />
          </div>
          <h4 className="receiver-details-name">{user.username}</h4>
        </div>
      </div>

      <Messages
        user={user}
        messages={messages}
        isMessagesLoading={isMessagesLoading}
      />

      <ChatInputBox handleMessageSend={handleMessageSend} />
    </div>
  );
};
