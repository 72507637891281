import {React,useState,useEffect} from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner/Spinner';
import imglogin from './loginimg.png';
import view from './view-hide.png';
import './Signin.css';
import elbg from './el-bg.png';
import axios from 'axios';

function Signup() {
    const navigate = useNavigate();
    useEffect(() =>{   
        const token = localStorage.getItem('token');
        if(token){
            navigate('/dashboard');
        }
        },[navigate]);

    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setloading] = useState(false);
    const movesignin = () => {
        window.location.href = '/signin';
    }
    
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [mobileNumber, setmobileNumber] = useState('');

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setloading(true);
        // if(password !== confirmPassword){
        //     alert("Password and Confirm Password are not same");
        // }
        const credentials = {
            "first_name":firstname,
            "last_name":lastname,
            "username":username,
            "password":password,
            "email":"sample@mail.com",
            "gender":"Other",
            "about": "Hey, I'm using MancMint!"
        }
        const backendurl = process.env.REACT_APP_BACKEND_URL;
        const url = `${backendurl}/api/signup/`;
        axios.post(url, credentials).then((res) => {
            if (res.status === 200) {
                
                localStorage.setItem('token', res.data.token.access_token);
                navigate('/signup/survey');
            } else {
                alert("Signup Failed");
            }
        }).catch((err) => {
            
            alert("Signup Failed");
        }
        )
    }

  return (
    <>
       <HomeNav/>
       <div className='signin-bx-1 fl-r'>
            <div className="signin-bx-2">
                <div className="signin-txt-bx ">
                    <div className="sign-in-txt-1">  Oh? <br /> Hello there</div>
                    <div className="sign-in-txt-2"> Already have account?<b onClick={movesignin}> Login</b></div>
                </div>
                <div className="signin-img1"> <img alt=''  src={imglogin} /></div>
            </div>
            <div className="signin-bx-3">
                <div className="signin-signup ">
                    <form action="" className="sign-in-form">
                        <div className="input-field">
                            <input type="text" name="firstname"  placeholder="Enter First Name" style={{
                                width:"19rem",
                                backgroundColor:"#d3d8d84f",
                                border:"#d3d8d84f"
                            }}
                            value={firstname}
                            onChange={(e)=>setfirstname(e.target.value)}
                            />
                        </div>
                        <div className="input-field">
                            <input type="text" name="lastname"  placeholder="Enter Last Name" style={{
                                width:"19rem",
                                backgroundColor:"#d3d8d84f",
                                border:"#d3d8d84f"
                            }}
                            value={lastname}
                            onChange={(e)=>setlastname(e.target.value)}
                            />
                        </div>
                        <div className="input-field">
                            <input type="text" name="username"  placeholder="Enter Username" style={{
                                width:"19rem",
                                backgroundColor:"#d3d8d84f",
                                border:"#d3d8d84f"
                            }}
                            value={username}
                            onChange={(e)=>setusername(e.target.value)}
                            />
                        </div>
                        {/* <div className="input-field">
                            <input type="text" name="mobilenumber"  placeholder="Enter Mobile Number" style={{
                                width:"19rem",
                                backgroundColor:"#d3d8d84f",
                                border:"#d3d8d84f"
                            }}
                            value={mobileNumber}
                            onChange={(e)=>setmobileNumber(e.target.value)}
                            />
                        </div> */}
                      
                        {/* <div className="input-field">
                                <select name="gender" onChange={onchange}>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div> */}
                            
{/*                       
                        <div className="input-field">
                            <input type="phonenumber" name="phonenumber"  placeholder="Enter Phonenumber"/>
                        </div> */}
                        {/* <div className="input-field">
                            <input type="email" name="email" value={credentials.email} placeholder="Enter Email ID" onChange={onchange} />
                        </div> */}

                        <div className="input-field">
                            <input type={passwordShown ? "text" : "password"} value={password} name="password" placeholder="Password" 
                               onChange={(e)=>setpassword(e.target.value)}
                               style={{
                                width:"19rem",
                                backgroundColor:"#d3d8d84f",
                                border:"#d3d8d84f"
                            }}
                            /><span><img className='viewpass' onClick={togglePasswordVisiblity} alt='' src={view} /></span>
                        </div>
                        {/* <div className="input-field">
                            <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={
                                (e)=>setconfirmPassword(e.target.value)
                            } />
                        </div> */}
                        {loading ? <Spinner /> : <button onClick={handleSubmit}>Sign Up</button>}
                    </form></div>
            </div>
        </div>
       <Footer/>
    </>
  )
}

export default Signup;
