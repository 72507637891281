import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Profile.css";
import SearchComp from '../../components/SearchComp/SearchComp';
import Modal from 'react-bootstrap/Modal';
import AuthContext from "../../Context/Auth/AuthContext";
import axios from 'axios';
import ModalBody from "react-bootstrap/esm/ModalBody";
import Loader from "../../components/Loader/Loader";
import InstaIcon from './insta_icon.png';
import TwitterIcon from './twitter_icon.png';
import LinkedInIcon from './linkedin_icon.png';

const SocialLink = ({ link, icon }) => {
  if (link === "") 
    return (
      <img src={icon} alt="social-icon" className="social-icon" />
    )
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="social-link">
      <img src={icon} alt="social-icon" className="social-icon"/>
    </a>
  )
}
const HireBoxLabel = ({ bgcolor, logo, name, id, handleSkillClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const style = {
    backgroundColor: isClicked ? '#0699e3' : bgcolor || '#FFCA404D',
    color: 'black',
    margin:"0.3rem",
    padding: '0.2rem 0.5rem',
    borderRadius: '1rem',
    fontSize: '0.7rem',
    fontWeight: '500',
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    handleSkillClick({ bgcolor, logo, name, id});
  };

  return (
    <button style={style} className='hire-label' onClick={handleClick}>
      <img src={logo} className='img-fluid' alt='skill-logo' style={{
          width:"1rem",
          height:"1rem",
      }}/>
      {' '}
      {name}
    </button>
  );
};

const Community = ({ community }) => {
  return (
    <div className="row">
      <div className="skill-div">
        <div className="skill-in">
          <img src={community.logo} alt="skill-icon" className="skill-icon"/>
          <p className="skill_name">{community.name}</p>
        </div>
      </div>
    </div>
  )
};

function Profile() {
  // Edit Skills Modal and its states
  const [editSkills, setEditSkills] = useState([]);
  const [editSkillsModalShow, setEditSkillsModalShow] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]); // State to store the selected skills

  const handleSkillClick = (skill) => {
    setSelectedSkills(prevSkills => {
      if(prevSkills.some(prevSkill => prevSkill.id === skill.id)) {
        return prevSkills.filter(prevSkill => prevSkill.id !== skill.id);
      } else {
        return [...prevSkills, skill];
      }
    });
  };

  const handleSkillsSave = (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    let combinedSkills = [...selectedSkills, ...editSkills];
    setUserData({...userData, skills: combinedSkills});
    
    axios.put(backendURL+'/api/profile/', {
      skills: combinedSkills},
      {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      }
    ).then((res) => {
      
      setEditSkillsModalShow(false);
      alert("Skills Updated Successfully");
    }).catch((error) => {
      
    });
  }

  const [skillModalShow, setSkillModalShow] = useState(false);
  const handleSkillModalClose = () => setSkillModalShow(false);

  const [communityModalShow, setCommunityModalShow] = useState(false);
  const handleCommunityModalClose = () => setCommunityModalShow(false);

  const [portfolioModalShow, setPortfolioModalShow] = useState(false);
  const handlePortfolioModalClose = () => setPortfolioModalShow(false);

  // Edit Profile Modal and its states
  const [editProfileModalShow, setEditProfileModalShow] = useState(false);
  const handleEditProfileModalClose = () => setEditProfileModalShow(false);
  const [editProfilePic, setEditProfilePic] = useState(null);
  const [editCoverPic, setEditCoverPic] = useState(null);

  const getFormattedLink = (link) => {
    if (link === '') return '';
    if(link.includes("https://")) {
      return link;
    } else {
      return `https://${link}`;
    }
  }

  const handleEditProbileSave = (e) => {
    e.preventDefault();
    
    let formData = new FormData();
    formData.append('first_name', userData.first_name);
    formData.append('last_name', userData.last_name);
    formData.append('email', userData.email);
    formData.append('about', userData.about);
    formData.append('twitter_link', getFormattedLink(userData.twitter_link));
    formData.append('instagram_link', getFormattedLink(userData.instagram_link));
    formData.append('linkedin_link', getFormattedLink(userData.linkedin_link));
    if (editProfilePic){
      formData.append('profile_pic', editProfilePic);
    }
    if (editCoverPic){
      formData.append('cover_pic', editCoverPic);
    }
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    axios.put(backendURL+'/api/profile/', formData, {
      headers: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      
      setUserData(res.data);
      alert("Profile Updated Successfully");
      setEditProfileModalShow(false);
    }).catch((error) => {
      
    });
  }

  const [portfolio, setPortfolio] = useState([]);
  const [portfolioEditModalShow, setPortfolioEditModalShow] = useState(false);
  const handlePortfolioEditModalClose = () => setPortfolioEditModalShow(false);
  const [inputPortfolio, setInputPortfolio] = useState("");

  const handlePortfolioSave = (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    let combinedPortfolio = [...portfolio, inputPortfolio];
    setUserData({...userData, portfolio: combinedPortfolio});
    
    axios.put(backendURL+'/api/profile/', {
      portfolio: combinedPortfolio},
      {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      }
    ).then((res) => {
      
      setPortfolioEditModalShow(false);
      alert("Portfolio Updated Successfully");
      window.location.reload();
    }).catch((error) => {
      
    });
  }

  const [projects,SetProjects] = useState([]);
  const [projectEditModalShow,setProjectEditModalShow]=useState(false);
  const handleProjectEditModalClose = () => setProjectEditModalShow(false);
  const [inputProject,setInputProject] = useState("");
  const [inputProjectSkills,setInputProjectSkills]=useState("");
  const [projectSelectedSkills,setProjectSelectedSkills]=useState([]);
  const [communities, setcommunities] = useState(null);

  const handleProjectSkillClick = (skill) => {
    setProjectSelectedSkills(prevSkills => {
      if(prevSkills.some(prevSkill => prevSkill.id === skill.id)) {
        return prevSkills.filter(prevSkill => prevSkill.id !== skill.id);
      } else {
        return [...prevSkills, skill];
      }
    });
  };

  const handleProjectSave = (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    let combinedProjects = [...projects, {name: inputProject, skills: projectSelectedSkills}];
    setUserData({...userData, projects: combinedProjects});
    
    axios.put(backendURL+'/api/profile/', {
      projects: combinedProjects},
      {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      }
    ).then((res) => {
      
      setProjectEditModalShow(false);
      alert("Projects Updated Successfully");
      window.location.reload();
    }).catch((error) => {
      
    });
  }

  const {userData, loading, setLoading,setUserData, skills, listSkills, apiCall } = useContext(AuthContext);

  const listCommunities = async () => {
    const { res, err } = await apiCall(`/api/group_chats`, 'get');
    
    res && setcommunities(res);
  }

  useEffect(() => {
    const fetchData = async () => {
      
      setLoading(true);
      const userToken = localStorage.getItem('token');
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      if (userToken){
        try {
          const res = await axios.get(backendURL+'/api/profile/',{
            headers: {
              'Authorization': `Bearer ${userToken}`
            }
          });
          listSkills();
          listCommunities();
          setUserData(res.data);
          setEditSkills(res.data.skills);
          setPortfolio(res.data.portfolio);
          SetProjects(res.data.projects);
          
          setLoading(false);
        } catch (error) {
          
        }
      }
      
    }
  
    fetchData();
  }, []);

  const reactIcon = "https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png";

  return (
    <>
    <div className="profile-page">
      <div>
        <Sidebar/>
      </div>
      {
        loading ? <Loader/> :
         <>
            <div className='profile-content container'>
              <div className='pb1 container'>
                <div className='header_profile row'>
                        <div className='header_profile-left col-md-8 col-lg-8'>
                            <h1 className="welcome-text">Welcome {userData.first_name}</h1>
                            <p className="day-date">
                                {new Date().toLocaleDateString("en-US", {
                                    weekday: "long",
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                })}
                            </p>
                        </div>
                        <div className='header_profile-right col-md-4 col-lg-4'>
                            <SearchComp />
                        </div>
                </div>
                <div className="profile-details">
                    <div className="cover-pic"
                        style={{
                          backgroundImage: `url(${userData.cover_pic || '/images/cover_pic.png'})`,
                          height: "30vh",
                          display:"flex",
                          alignItems:"flex-end",
                          justifyContent:"end"
                        }}
                    >
                        <button className="btn edit-cover-btn" onClick={
                          () => {
                            setEditProfileModalShow(true);
                          }
                        }><i className="bi bi-pencil-fill"/>{' '}Edit Profile</button>
                    </div>
                    <div className="row" style={{marginBottom:"0.0rem"}}>
                        <div className="col-md-6 col-lg-6">
                            <div className="profile-d-div">
                                <img src={userData.profile_pic || '/images/profile_pic.png'} alt="profile-pic" className="pp-profile-pic"/>
                                <div className="profile-details">
                                  <h2 className="pp-profile-name">{userData.first_name}{' '}{userData.last_name}</h2>
                                  <p className="pp-profile-username">@{userData.username}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pc-content">
                        <div className="col-md-9 col-lg-9 pc-content-left">
                          <div className="row">
                              <div className="col-md-6 col-lg-6 pc-content-about">
                                  <h6 style={{fontWeight:"700"}}>About:</h6>
                                  <p className="profile-about-text">{userData.about}</p>
                              </div>
                                <div className="col-md-5 col-lg-5 pc-content-skills">
                                    <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>My Skill Set:</h6>
                                        <button className="edit-btn" onClick={
                                          () => {
                                            setEditSkillsModalShow(true);
                                          }
                                        }><i className="bi bi-pencil-fill"/></button>
                                    </div>
                                    <div className="">
                                      {userData.skills.slice(0, 3).map((skill)=>(
                                        <div className="row">
                                          <div className="skill-div">
                                            <div className="skill-in">
                                              <img src={skill.logo} alt="skill-icon" className="skill-icon"/>
                                              <p className="skill_name">{skill.name}</p>
                                            </div>
                                            <div className="p-bar">
                                              <div className='progress-bar-skill' style={{width: `${skill.score}%`, backgroundColor:"#FF3553"}}></div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    {
                                      userData?.skills?.length > 3 ? (
                                          <div className="row text-center">
                                            <button className="btn viewmore-skills-btn"  onClick={() => {setSkillModalShow(true);}}>View More</button>
                                          </div>
                                      ) : null
                                    }
                                </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4 col-lg-4 pc-content-communities">
                                    <h6 style={{fontWeight:"600"}}>My Communities:</h6>
                                    <div className="">
                                      {communities?.length === 0
                                        ? "No communities joined"
                                        : communities?.slice(0, 3)?.map((community)=>(
                                            <Community community={community} key={community.community_id} />
                                          ))
                                      }
                                    </div>
                                    {
                                      communities
                                        ? (communities?.length > 3
                                          ? (<div className="row text-center">
                                              <button className="btn viewmore-skills-btn" onClick={() => {setCommunityModalShow(true);}}>View More</button>
                                            </div>)
                                          : null)
                                        : <p> Loading ... </p>
                                    }
                              </div>
                              <div className="col-md-3 col-lg-3 pc-content-socialnetwork">
                                  <h6 style={{fontWeight:"600"}}>My Social Network:</h6>
                                  <div className=" social-handles">
                                    <SocialLink link={userData.instagram_link} icon={InstaIcon} />
                                    <SocialLink link={userData.twitter_link} icon={TwitterIcon} />
                                    <SocialLink link={userData.linkedin_link} icon={LinkedInIcon} />
                                    
                                  </div>
                              </div>
                              <div className="col-md-4 col-lg-4 pc-content-portfolio">
                                    <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>My Portfolio:</h6>
                                        <button className="edit-btn" onClick={
                                          () => {
                                            setPortfolioEditModalShow(true);
                                          }
                                        }><i className="bi bi-pencil-fill"/></button>
                                    </div>
                                    <div className="">
                                      {userData?.portfolio?.filter(portfolio => portfolio.trim() !== "").length > 0
                                        ? userData?.portfolio?.slice(0, 3).map((portfolio) => (
                                            portfolio !== '' && (
                                              <div className="row">
                                                <div className="skill-div">
                                                  <div className="skill-in">
                                                  <i className="bi bi-person-vcard" style={{
                                                    marginRight:"0.5rem"
                                                  }}/>
                                                    <a href={`https://${portfolio}`} target="_blank" className="skill_name" style={{
                                                      textDecoration:"none",
                                                      color:"#000"
                                                    }}>{portfolio}</a>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          ))
                                        : <p>No portfolio links available</p>}
                                        {
                                        userData && userData.portfolio && userData.portfolio.length > 3 ? (
                                            <div className="row text-center">
                                              <button className="btn viewmore-skills-btn"  onClick={() => {setPortfolioModalShow(true);}}>View More</button>
                                            </div>
                                        ) : null
                                    }
                                    </div>
                              </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 pc-content-right">
                                <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>Personal Projects:</h6>
                                        <button className="edit-btn" onClick={
                                          () => {
                                            setProjectEditModalShow(true);
                                          }
                                        }><i className="bi bi-pencil-fill"/></button>
                                    </div>
                                <div className="personal-projects">
                                {
                                  projects?.filter(project => project.name.trim() !== "").length > 0 ? (
                                    projects?.map((project) => (
                                      project.name.trim() !== "" && (
                                        <div className="row">
                                          <div className="skill-div">
                                            <div className="skill-in">
                                              <li>
                                                {project.name}
                                              </li>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    ))
                                  ) : (
                                    <p>No Projects available</p>
                                  )
                                }
                                </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            {/* Modals for profile sections */}
            {/* Modal for skills */}
            <Modal
                show={skillModalShow}
                onHide={handleSkillModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Skill Set:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                      {userData.skills.map((skill)=>(
                        <div className="row">
                          <div className="skill-div">
                            <div className="skill-in">
                              <img src={skill.logo} alt="skill-icon" className="skill-icon"/>
                              <p className="skill_name">{skill.name}</p>
                            </div>
                            <div className="p-bar">
                              <div className='progress-bar-skill' style={{width: `${skill.score}%`, backgroundColor:"#FF3553"}}></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                </Modal.Body>
            </Modal>
            {/* Modal for communities */}
            <Modal
                show={communityModalShow}
                onHide={handleCommunityModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Communities:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {communities?.map((community)=>(<Community community={community} />))}
                  </div>
                </Modal.Body>
            </Modal>
            {/* Modal for portfolio */}
            <Modal
                show={portfolioModalShow}
                onHide={handlePortfolioModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Portfolio:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {userData.portfolio.map((portfolio)=>(
                      portfolio!=="" && (<div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <img src={reactIcon} alt="skill-icon" className="skill-icon"/>
                            <a href={portfolio} target="_blank" className="skill_name" style={{
                                                textDecoration:"none",
                                                color:"#000"
                                              }}>{portfolio}</a>
                          </div>
                        </div>
                      </div>)
                    ))}
                  </div>
                </Modal.Body>
            </Modal>
            {/* Modal for edit profile */}
            <Modal
                show={editProfileModalShow}
                onHide={handleEditProfileModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    Edit Profile:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                        <label htmlFor="profile_pic" className="form-label">Profile Picture:</label>
                        <input type="file" name="profile_pic" id="profile_pic" className="form-control" 
                          onChange={(e) => setEditProfilePic(e.target.files[0])}
                        style={{
                          marginBottom:"1rem"
                        }}/>
                        <label htmlFor="cover_pic" className="form-label">Cover Picture:</label>
                        <input type="file" name="cover_pic" id="cover_pic" className="form-control" 
                          onChange={(e) => setEditCoverPic(e.target.files[0])}
                        style={{
                          marginBottom:"1rem"
                        }}/>
                        <label htmlFor="first_name" className="form-label">First Name:</label>
                        <input type="text" name="first_name" id="first_name" className="form-control" value={userData.first_name}
                          onChange={(e) => setUserData({...userData, first_name: e.target.value})}
                          style={{
                            marginBottom:"1rem",
                            border:"1px #dee2e6 solid",
                            width:"100%"
                        }}/>
                        <label htmlFor="last_name" className="form-label">Last Name:</label>
                        <input type="text" name="last_name" id="last_name" className="form-control" value={userData.last_name} 
                          onChange={(e) => setUserData({...userData, last_name: e.target.value})}
                          style={{
                            marginBottom:"1rem",
                            border:"1px #dee2e6 solid",
                            width:"100%"
                        }}/>
                        <label htmlFor="username" className="form-label">Email:</label>
                        <input type="text" name="email" id="email" className="form-control" placeholder="email" value={userData.email}
                          onChange={(e) => setUserData({...userData, email: e.target.value})}
                          style={{
                            marginBottom:"1rem",
                            border:"1px #dee2e6 solid",
                            width:"100%"
                        }}/>
                        <label htmlFor="username" className="form-label">About:</label>
                        <textarea name="about"  id="about" className="form-control" placeholder="About" value={userData.about}  rows="5" 
                          onChange={(e) => setUserData({...userData, about: e.target.value})}
                        style={{
                          marginBottom:"1rem"
                        }}/>
                        <label htmlFor="socialnetworks" className="form-label">Social Networks:</label><br/>
                        <label htmlFor="twitter" className="form-label">Twitter:</label>
                        <input type="text" name="twitterurl" id="twitterurl" className="form-control" value={userData.twitter_link} placeholder="Enter Profile URL" 
                          onChange={(e) => setUserData({...userData, twitter_link: e.target.value})}
                        style={{
                          marginBottom:"1rem",
                          border:"1px #dee2e6 solid",
                          width:"100%"
                        }}/>
                        <label htmlFor="instagram" className="form-label">Instagram:</label>
                        <input type="text" name="twitterurl" id="twitterurl" className="form-control" value={userData.instagram_link} placeholder="Enter Profile URL" 
                          onChange={(e) => setUserData({...userData, instagram_link: e.target.value}) }
                        style={{
                          marginBottom:"1rem",
                          border:"1px #dee2e6 solid",
                          width:"100%"
                        }}/>
                        <label htmlFor="linkedin" className="form-label">LinkedIn:</label>
                        <input type="text" name="twitterurl" id="twitterurl" className="form-control" value={userData.linkedin_link} placeholder="Enter Profile URL" 
                          onChange={(e) => setUserData({...userData, linkedin_link: e.target.value})}
                        style={{
                          marginBottom:"1rem",
                          border:"1px #dee2e6 solid",
                          width:"100%"
                        }}/>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" 
                    onClick={handleEditProbileSave}
                  style={{
                    backgroundColor:"#1594D4",
                    color:"#fff",
                    fontWeight:"400" 
                  }}>Save Changes</button>
                </Modal.Footer>
            </Modal>
            {/*Modal for edit skills */}
            <Modal
                show={editSkillsModalShow}
                onHide={() => setEditSkillsModalShow(false)}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    Edit Skills:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {editSkills.map((skill, index) => (
                      <div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <img src={skill.logo} alt="skill-icon" className="skill-icon"/>
                            <p className="skill_name">{skill.name}</p>
                          </div>
                          <div>
                            <button className="edit-btn" onClick={() => {
                              let newSkills = [...editSkills];
                              newSkills.splice(index, 1);
                              setEditSkills(newSkills);
                            }}><i className="bi bi-trash-fill"/></button>
                        </div>
                      </div>
                      </div>
                    ))}
                    <h6>
                           Add a new Skill:
                    </h6>
                    <div className='q1-options1 lables'>
                    {
                        skills.filter(skill => !userData.skills.some(userSkill => userSkill.id === skill.id)).map((skill) => (
                          <HireBoxLabel
                              id={skill.id}
                              bgcolor={skill.bgcolor}
                              logo={skill.logo}
                              name={skill.name}
                              handleSkillClick={handleSkillClick}
                          />
                      ))
                    }
                </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" 
                    onClick={handleSkillsSave}
                  style={{
                    backgroundColor:"#1594D4",
                    color:"#fff",
                    fontWeight:"400" 
                  }}>Save Changes</button>
                </Modal.Footer>
            </Modal>
            {/* Modal for edit portfolio */}
            <Modal
                show={portfolioEditModalShow}
                onHide={handlePortfolioEditModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    Edit Portfolio:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {portfolio.map((pf,index)=>(
                      pf!=="" && (<div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <img src={reactIcon} alt="skill-icon" className="skill-icon"/>
                            <p className="skill_name">{pf}</p>
                          </div>
                          <div>
                            <button className="edit-btn" onClick={() => {
                            let newPortfolio = [...portfolio];
                            newPortfolio.splice(index, 1);
                            newPortfolio = newPortfolio.filter(item => item !== ''); // remove empty strings
                            setPortfolio(newPortfolio);
                            }}><i className="bi bi-trash-fill"/></button>
                          </div>
                        </div>
                      </div>)
                    ))}
                  </div>
                  <div>
                    <h6>
                           Add a new Portfolio:
                    </h6>
                    <input type="text" name="portfolio" id="portfolio" className="form-control" placeholder="Enter Portfolio URL" 
                      onChange={(e) => setInputPortfolio(e.target.value)}
                      style={{
                        marginBottom:"1rem",
                        border:"1px #dee2e6 solid",
                        width:"100%"
                      }}/>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" 
                    onClick={handlePortfolioSave}
                  style={{
                    backgroundColor:"#1594D4",
                    color:"#fff",
                    fontWeight:"400" 
                  }}>Save Changes</button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={projectEditModalShow}
                onHide={handleProjectEditModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered

            >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    Edit Projects:
                  </Modal.Title>
                </Modal.Header>
              <ModalBody>
                <div>
                  {projects.map((pr,index)=>(
                    pr.name!=="" && (<div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <p className="skill_name">{pr.name}</p>
                          </div>
                          <div>
                            <button className="edit-btn" onClick={() => {
                              let newProjects = [...projects];
                              newProjects.splice(index, 1);
                              SetProjects(newProjects);
                            }}><i className="bi bi-trash-fill"/></button>
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                </div>
                <h6>Add New Project:</h6>
                <input type="text" name="projectTitle" id="projectTitle" placeholder="Enter Project Title" onChange={
                  (e) => {
                    setInputProject(e.target.value)
                  }
                }/>
                <h6>Select skills used:</h6>
                <div className='q1-options1 lables'>
                    {
                        skills.map((skill) => (
                          <HireBoxLabel
                              id={skill.id}
                              bgcolor={skill.bgcolor}
                              logo={skill.logo}
                              name={skill.name}
                              handleSkillClick={handleProjectSkillClick}
                          />
                      ))
                    }
                </div>
              </ModalBody>
              <Modal.Footer>
                  <button className="btn" 
                    onClick={handleProjectSave}
                  style={{
                    backgroundColor:"#1594D4",
                    color:"#fff",
                    fontWeight:"400" 
                  }}>Save Changes</button>
                </Modal.Footer>
            </Modal>
         </>
      }
    </div>
    </>
  )
}

export default Profile;