import React from "react";
import "./Join.css";
import JoinImg from './Saly13.png';

export default function Join() {
  return (
    <div id="joinbox" className=" wow fadeInUp" data-wow-delay=".1s">
      <div className="join container">
        <div className="row">
          <div className="col-lg-4 col-md-12"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            
            }}
          >
            <img
              className="joinimg mx-auto"
              src={JoinImg}
              alt="join"
            />
          </div>
          <div className="col-lg-8 col-md-12 row-join">
            <h3 className="jointext">
              Grow with the <b>MancMint</b> <br/>community and upskill <br/>yourself!
            </h3>
            <button className="joinbtn btn-lg btn-dark btn" onClick={
                () => {
                    window.location.href = '/signin'
                }
            }>Join Now</button>
          </div>
        </div>
      </div>
    </div>
  );
}