import React, { useEffect,useState,useContext } from 'react'
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import './Signupsurvey.css';
import axios from 'axios';
import AuthContext from '../Context/Auth/AuthContext';
import party from './party.png';
import Loader from '../components/Loader/Loader';

const HireBoxLabel = ({ bgcolor, logo, name, id, handleSkillClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const style = {
    backgroundColor: isClicked ? '#0699e3' : bgcolor || '#FFCA404D',
    color: 'black',
    margin:"0.3rem",
    padding: '0.2rem 0.5rem',
    borderRadius: '1rem',
    fontSize: '0.7rem',
    fontWeight: '500',
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    handleSkillClick({ bgcolor, logo, name, id});
  };

  return (
    <button style={style} className='hire-label' onClick={handleClick}>
      <img src={logo} className='img-fluid' alt='skill-logo' style={{
          width:"1rem",
          height:"1rem",
      }}/>
      {' '}
      {name}
    </button>
  );
};

function Signupsurvey() {
    const navigate = useNavigate();
    const {loading,setLoading} = useContext(AuthContext);
    const [skills, setSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]); // State to store the selected skills
    const [experience, setExperience] = useState(""); // State to store the experience
    const [role, setRole] = useState(""); // State to store the role
    const [name, setName] = useState(""); // State to store the name
    const [email, setEmail] = useState(""); // State to store the email
    const [phoneNumber, setPhoneNumber] = useState("");
    const [portfolioLink, setPortfolioLink] = useState(""); // State to store the portfolio link
    const [portfolioLinks, setPortfolioLinks] = useState([]); // State to store the portfolio links
    const [dummyNone, setDummyNone] = useState(""); // State to store the portfolio links


    const token = localStorage.getItem('token');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchSkills = async () => {
          setLoading(true);
          const { data } = await axios.get(BACKEND_URL+'/api/skills/',{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setLoading(false);
          setSkills(data);
          // 
        };
        fetchSkills();
      }, []);

      const handleSkillClick = (skill) => {
        setSelectedSkills(prevSkills => {
          if(prevSkills.some(prevSkill => prevSkill.id === skill.id)) {
            return prevSkills.filter(prevSkill => prevSkill.id !== skill.id);
          } else {
            return [...prevSkills, skill];
          }
        });
      };
    
    // 

    const handleSubmit = async (e) => {
        e.preventDefault();

        let message = null;
        if (experience === '') {
          message = 'Please select your experience';
        } else if (role === '') {
          message = 'Please select your role';
        } else if (name === '') {
          message = 'Please enter your name';
        } else if (email === '') {
          message = 'Please enter your email';
        } else if (phoneNumber === '') {
          message = 'Please enter your phone number';
        } 

        if (message !== null) {
          alert(message);
          return;
        }
        
        const surveyData={
          "skills": selectedSkills,
          "experience": experience,
          "role": role,
          "mobile_number": phoneNumber,
          "email": email,
          "portfolio": portfolioLinks.filter(link => link !== ""),
          "m_coins": 520
        }
        
        const backendurl = process.env.REACT_APP_BACKEND_URL;
        const url = `${backendurl}/api/profile/`;

        axios.put(url, surveyData,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
            if (res.status === 200) {
                window.location.href = '/dashboard';
            } else {
                alert("Submission Failed");
            }
        }).catch((err) => {
            
            alert("Submission Failed");
        }
        )
    };

  return (
    <>
        <HomeNav/>
        {
            loading ? <Loader /> : 
            <form onSubmit={handleSubmit}>
              <div className='container survey-page'>
                <h3 className='survey-question'>1. What are your skills?</h3>
                <div className='q1-options lables'>
                    {
                        skills.map((skill) => (
                            <HireBoxLabel
                            id={skill.id}
                            bgcolor={skill.bgcolor}
                            logo={skill.logo}
                            name={skill.name}
                            handleSkillClick={handleSkillClick}
                            />
                        ))
                    }
                </div>
                <h3 className='survey-question'>2. How many years of experience do you have?</h3>
                <div className='q1-options'>
                  <label>
                    <input type="radio" required className='radio-exp' value="Less than 1 Year" checked={experience === "Less than 1 Year"} onChange={(e) => setExperience(e.target.value)} />
                    Less than 1 Year
                  </label> <br/>
                  <label>
                    <input type="radio" className='radio-exp' value="1+ Years" checked={experience === "1+ Years"} onChange={(e) => setExperience(e.target.value)} />
                    1+ Years
                  </label> <br/>
                  <label>
                    <input type="radio"  required className='radio-exp'  value="2+ Years" checked={experience === "2+ Years"} onChange={(e) => setExperience(e.target.value)} />
                    2+ Years
                  </label><br/>
                  <label>
                    <input type="radio"  required className='radio-exp' value="5+ Years" checked={experience === "5+ Years"} onChange={(e) => setExperience(e.target.value)} />
                    5+ Years
                  </label><br/>
                </div>
                <h3 className='survey-question'>3. Are you?</h3>
                <div className='q1-options'>
                  <label>
                    <input type="radio" className='radio-exp' value="Looking to Hire" checked={role === "Looking to Hire"} onChange={(e) => setRole(e.target.value)} />
                    Looking to Hire
                  </label> <br/>
                  <label>
                    <input type="radio"  className='radio-exp' value="Looking to get Hired" checked={role === "Looking to get Hired"} onChange={(e) => setRole(e.target.value)} />
                    Looking to get Hired
                  </label> <br/>
                </div>
                <h3 className='survey-question'>4. Subscriber to “MancLetters” by provinding:</h3>
                <div className='q1-options'>
                      <input type="text" value={name} placeholder='Name...' onChange={(e) => setName(e.target.value)} /> <br/>
                      <input type="email" value={email} placeholder='Email...' onChange={(e) => setEmail(e.target.value)} /> <br/>
                      <input type="text" value={phoneNumber} placeholder='Phone Number...' onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>
                <h3 className='survey-question'>5. Dropdown your portfolio link (if any)</h3>
                <div className='q1-options'>
                      <input type="radio" className='radio-exp' value="yes"  checked={!dummyNone} onChange={(e) => setDummyNone(false)}/>
                      <input type="text" value={portfolioLink} placeholder='Enter the link' disabled={dummyNone}
                          onChange={(e) => {
                            setPortfolioLink(e.target.value)
                            setPortfolioLinks([portfolioLink.startsWith('https://') ? portfolioLink: `https://${portfolioLink}`])
                          }}
                        /> <br/>
                      {
                        portfolioLink.length === 0 ? <><label>
                        <input type="radio" className='radio-exp' value="None"  checked={dummyNone} onChange={(e) => { setDummyNone(true); setPortfolioLinks([]); }}/>
                        None
                      </label> <br/></> : null
                      }
                </div>
                <div className='row survey-btm'>
                    <button className='btn submit-survey' 
                    type='submit'>Submit</button>
                </div>
                <hr/>
                <div className='row text-center'>
                    <h3 className='wooho'>
                      <img src={party} alt='party' style={{
                        width:"3rem",
                        height:"3rem",
                        marginRight:"0.5rem"
                      
                      }}/>
                      Wohoo.....You earn 520 mCoins for Signing Up!
                    </h3>
                </div>
            </div>
            </form>
        }
        <Footer/> 
    </>
  )
}

export default Signupsurvey;