import { createContext, useState } from 'react';

export const intialState = {
    chatsList: null,
    isChatsListLoading: true,

    unreadChatsCount: 0,

    isChatOpen: false,
    openedUserChat: {
        user: null,
        messages: null,
        isMessagesLoading: false,
        socket: null,
    },
    isMiniWindow: window.innerWidth <= 1050,
}

const ChatContext = createContext([{}, () => { }]);

const ChatProvider = (props) => {
    const [state, setState] = useState(intialState);
    return (
        <ChatContext.Provider value = {[state, setState]}>
            { props.children }
        </ChatContext.Provider>
    )
}

export { ChatContext, ChatProvider };
