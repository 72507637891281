import { React, useState,useContext, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Dashboard.css";
import b1c1img from "./b1c1img.png";
import SkillBox from "../../components/SkillBox/SkillBox";
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  CartesianGrid,
  Tooltip,
  Bar,
  BarChart,
  ResponsiveContainer
} from "recharts";
import SearchComp from "../../components/SearchComp/SearchComp";
import HomeChats from "../../components/HomeChats/HomeChats";
import AuthContext from "../../Context/Auth/AuthContext";
import axios from "axios";
import { Form, Modal } from "react-bootstrap";
import { SkillTestForm } from "../../components/SkillTest/SkillTestForm";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import profileAvatar from './profileAvatar.png';



function Dashboard() {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();

  const {
    userData, getUserData, 
    loading, setLoading, apiCall,
  } = useContext(AuthContext);

  const [skillScores, setSkillScores] = useState([]);
  const [previousWork, setPreviousWork] = useState([]);
  const [showSkillTestModal, setSkillTestModal] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
  
      getUserData();
      const apiCalls = [
        apiCall('/api/skill_test/result/'),
        apiCall('/api/user/skills/graph/'),
        apiCall('/api/chats?count=3')
      ];
  
      const results = await Promise.all(apiCalls);
  
      const [skillScoresResult, previousWorkResult, chatsResult] = results.map(result => result.res);
      const errors = results.map(result => result.err);
  
      if (errors.some(err => err)) {
        
      } else {
        setSkillScores(skillScoresResult);
        setPreviousWork(previousWorkResult);
        setChats(chatsResult);
      }
  
      setLoading(false);
    })();
  }, []);

  
  return (
    <>
      <div className="dashboard-page">
        <div>
          <Sidebar />
        </div>
        {
          loading ? <Loader/> : 
          <div className="dashboard-content">
            <Modal
                show={showSkillTestModal}
                onHide={() => setSkillTestModal(false)}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    Take Test
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <SkillTestForm skills={userData.skills} apiCall={apiCall} closeModal={() => setSkillTestModal(false)}/>
                  </div>
                </Modal.Body>
                {/* <Modal.Footer>
                  <button className="btn" 
                    onClick={() => null}
                  style={{
                    backgroundColor:"#1594D4",
                    color:"#fff",
                    fontWeight:"400" 
                  }}>Save Changes</button>
                </Modal.Footer> */}
            </Modal>
            <div className="b1">
              <div className="container b1c">
                <h1 className="welcome-text"> Welcome, {userData.first_name}</h1>
                <p className="day-date">
                  {new Date().toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </p>
                <div className="search-box-mobile">
                    <SearchComp paddingLeft="0px"/>
                </div>
                <div className="b1c1 row">
                  <div className="col-md-6">
                    <div className="b1c1c1 text-left">
                      <h5>Reach out to more people!</h5>
                      <p className="b1c1-p">
                        Want to upskill yourself, and grow your network? Join our
                        exclusive masterclass and community!
                      </p>
                      <button 
                        onClick={() => navigate('/dashboard/communities')} 
                        className="btn  b1c1-btn"
                      >Join Now</button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="b1c1c3">
                      <img src={b1c1img} alt="b1c1img" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="b1c2 row">
                  <div className="col-md-6">
                    <div className="b1c2c1 container">
                      <div className="header_skills">
                        <h6>Your Skills</h6>{" "}
                        <button
                          className="btn b1c2c1-btn"
                          // onClick={() => {
                          //   alert("Add Skills");
                          // }}
                        >
                          <i class="fa-solid fa-ellipsis"></i>
                        </button>
                      </div>
                      <div className="row">
                        { 
                          loading
                            ? <Loader />
                            : userData.skills?.length > 0
                                ? userData.skills.slice(0, 3).map((skill) => (
                                    <SkillBox
                                      key={skill.name}
                                      title={skill.name}
                                      level={skill.level}
                                      imgSrc={skill.logo}
                                    />))
                                : <SkillBox
                                    key={1}
                                    title={"No skills available"}
                                  />
                                }
                      </div>
                      <div className="text-center">
                        {userData && userData?.skills && userData.skills.length > 3 && (
                          <a href="/dashboard/profile" className="b1c2c1-a">
                            View All
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="b1c2c2 container">
                      <div className="header_skills">
                        <h6>Skill Score</h6>{" "}
                        <button
                          className="btn b1c2c1-btn"
                        >
                          <i class="fa-solid fa-ellipsis"></i>
                        </button>
                      </div>
                      <div
                        className="container"
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <AreaChart
                          width={180}
                          height={180}
                          fontSize={10}
                          data={skillScores}
                          className="chart"
                          margin={{ top: 10, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorPv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#82ca9d"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="#82ca9d"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                        <XAxis dataKey="month" />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="average_score"
                            stroke="#82ca9d"
                            fillOpacity={1}
                            style={{ fontSize: ".3rem" }}
                            fill="url(#colorPv)"
                          />
                        </AreaChart>
                        <button className="btn take-test-btn" onClick={() => setSkillTestModal(true)}>
                          Take Test Again
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="b2">
                <div className="container b2c">
                    <div className="row search-box" style={{
                        paddingLeft: "10vw",
                    }}>
                      <SearchComp />
                    </div>
                    <div className="chats-box">
                        <h4>Chats</h4>
                        <div className="chats">
                          {
                            chats.length === 0
                              ? <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    height: "215px",
                                    padding: "0.5rem 1rem",
                                  }}
                                > No chats available </div>
                              : chats.map((chat) => (
                                  <HomeChats
                                    key={chat.user_id}
                                    profilePic={chat.profile_pic || profileAvatar}
                                    name={chat.first_name}
                                    lastMessage={chat.last_message.content}
                                    timestamp={chat.last_message.timestamp}
                                    activeStatus={false} 
                                  />
                                ))
                          }
                        </div>
                    </div>
                    <div className="row b2c1 container">
                        <div className="col-md-6 b2c1c1">
                            <h6 style={{
                                fontSize: "1.2rem",
                            }}>
                                Previous Work
                            </h6>
                            {previousWork.length === 0
                              ? <h6
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    height: "30vh",
                                    padding: "0.5rem 1rem",
                                  }}
                                >
                                  No previous work available
                                </h6>
                              : (<div className="bar-div">
                                    <BarChart
                                        width={230}
                                        height={280}
                                        fontSize={10}
                                        data={previousWork}
                                        margin={{ top: 15, bottom: 0, left: -40 }}
                                    >
                                        <CartesianGrid vertical={false} />
                                        <XAxis dataKey="skill_name" />
                                        <YAxis axisLine={false} />
                                        <Tooltip />
                                        <Bar dataKey="projects_count" fill="#1594D4" />
                                    </BarChart>
                                </div>)
                            }
                        </div>
                        <div className="col-md-6 b2c1c2">
                            <h5>
                                You earned
                            </h5>
                            <h1 style={{
                                fontSize: "3.0rem",
                                fontWeight: "600",
                                color: "#1594D4",
                                marginTop: "1rem",
                                marginBottom: "1rem",
                            }}>
                                {userData.m_coins ? userData.m_coins : 0} <span style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "600",
                                }}>mCoins</span>
                            </h1>
                            <p className="text-center" style={{
                                color:"#808191",
                                fontSize: "0.7rem",
                                marginBottom: "2rem",
                            }}>
                            Redeem your mCoins with <br/> our exclusive store
                            </p>
                            <button className="btn redeem-btn" style={{
                                color:"#1594D4",
                                border:"none",
                                boxShadow: "0px 0px 20px 0px #00000050",
                                padding: "0.5rem 2rem",
                            }} disabled> Reedem Now!
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          </div>

        }
      </div>
    </>
  );

}

export default Dashboard;
