import React, { useState, useEffect,useContext } from 'react';
import { Form, Button, FormGroup } from 'react-bootstrap';
import './JobForm.css';
import AuthContext from '../../Context/Auth/AuthContext';
import Loader from '../Loader/Loader';

const JobForm = () => {
  const { loading, listSkills, skills, postJob,setLoading } = useContext(AuthContext);
  
  const [jobTitle, setJobTitle] = useState('');
  const [employmentType, setEmploymentType] = useState('full-time'); // Default to full-time
  const [workLocation, setWorkLocation] = useState('on-site'); // Default to on-site
//   const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillSearch, setSkillSearch] = useState('');
  const [skillSuggestions, setSkillSuggestions] = useState([]);
  const [applicationLink, setApplicationLink] = useState('');

  useEffect(() => {
    listSkills();
    if(skills){
        setLoading(false);
    }
  }, []);

const handleSkillSearch = (e) => {
    // Filter skills based on user input
    setSkillSearch(e.target.value);
    const filteredSkills = skills.filter(skill =>
      skill.name.toLowerCase().includes(skillSearch.toLowerCase())
    );

    // If skillSearch is not in filteredSkills, add it
    if (!filteredSkills.some(skill => skill.name.toLowerCase() === e.target.value.toLowerCase())) {
      filteredSkills.push({ name: e.target.value.toLowerCase(), logo: 'https://res.cloudinary.com/djof8rl5r/image/upload/v1/media/skill_logo/django-python-logo_yueymo', bgcolor:"" });
    }

    setSkillSuggestions(filteredSkills);
  };

  const handleSkillSelect = (selectedSkill) => {
    setSelectedSkills([...selectedSkills, selectedSkill]);
    setSkillSearch('');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    //check if all the fields are filled
    if(!jobTitle || !employmentType || !workLocation || !selectedSkills || !applicationLink){
        alert('Please fill all the fields');
        return;
    }
    // Handle form submission, send data to backend, etc.

    const jobData = {
      "title":jobTitle,
      "employment_type":employmentType,
      "work_location":workLocation,
      "selected_skills":selectedSkills,
      "application_link":applicationLink.startsWith('https://') ? applicationLink : 'https://' + applicationLink,
      "salary":0,
    };

    postJob(jobData);
  };

  return (
    <>
        {
            loading ? <Loader />:
            <div className='job-form container'>
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="jobTitle" className='formGroup'>
            <Form.Label className='label-text'>Job Title:</Form.Label>
            <Form.Control type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder='Enter the Job title' style={{
              width: '100%',
            }}/>
        </Form.Group>

        <Form.Group controlId="employmentType" className='formGroup'>
            <Form.Label className='label-text'>Employment Type:</Form.Label>
            <div>
                <Form.Check
                inline
                type="radio"
                label="Full Time"
                value="full-time"
                checked={employmentType === 'full-time'}
                onChange={() => setEmploymentType('full-time')}
                />
                <Form.Check
                inline
                type="radio"
                label="Part Time"
                value="part-time"
                checked={employmentType === 'part-time'}
                onChange={() => setEmploymentType('part-time')}
                />
            </div>
        </Form.Group>

        <Form.Group controlId="workLocation" className='formGroup'>
            <Form.Label className='label-text'>Work Location:</Form.Label>
            <div>
            <Form.Check
            type="radio"
            label="On-site"
            value="on-site"
            checked={workLocation === 'on-site'}
            onChange={() => setWorkLocation('on-site')}
            inline
            />
            <Form.Check
            type="radio"
            label="Remote"
            value="remote"
            checked={workLocation === 'remote'}
            onChange={() => setWorkLocation('remote')}
            inline
            />
            <Form.Check
            type="radio"
            label="Hybrid"
            value="hybrid"
            checked={workLocation === 'hybrid'}
            onChange={() => setWorkLocation('hybrid')}
            inline
            />
            </div>
        </Form.Group>

        <FormGroup controlId="skills" className='formGroup'>
            <Form.Label className='label-text'> Required skills:</Form.Label>
            <Form.Control
            type="text"
            value={skillSearch}
            onChange={handleSkillSearch}
            onBlur={handleSkillSearch}
            placeholder='Enter the skills'
            style={{
              width: '100%',
            }}
            />
            <ul className='ul-skillsearch'>
            {skillSearch ? (
            skillSuggestions.map(skill => (
              <li key={skill.name} onClick={() => handleSkillSelect(skill)} className='li-skillsearch'>
                {skill.name}
              </li>
            ))
          ) : null}
            </ul>
            <div>
            {/* <span className='label-text'>Selected Skills:</span>  */}
            {selectedSkills.map(skill => skill.name).join(', ')}
            </div>
        </FormGroup>
        <Form.Group controlId="jobTitle" className='formGroup'>
            <Form.Label className='label-text'>Application Link:</Form.Label>
            <Form.Control type="text" value={applicationLink} onChange={(e) => setApplicationLink(e.target.value)} placeholder='Enter application link' style={{
              width: '100%',
            }}/>
        </Form.Group>
        <div className='text-center'>
            <Button className='hirenow-submit' type="submit" >
                Submit
            </Button>
        </div>
        </Form>
    </div>
        }
    </>
  );
};

export default JobForm;
