import React from 'react';
import './CommunityBox.css';
import startup from './startup.png';
import tech from './tech.png';
import finance from './finance.png';
import music from './music.png';
import growth from './growth.png';
import gaming from './gaming.png';
import coding from './coding.png';
import design from './design.png';
import web3 from './web3.png';

function CommunityBox() {
  const handleOnClick = () => {
    //window location object
    window.location.href = '/signin';
  }
  return (
    <div className='container text-center'>
       <div className='row'>
            <div className='col-md-4 col-sm-12 col-lg-4'>
                  <img src={startup} alt='startup' className='comm-img' onClick={handleOnClick}/>
            </div>
            <div className='col-md-4 col-sm-12 col-lg-4'>
                  <img src={tech} alt='tech' className='comm-img'/>
            </div>
            <div className='col-md-4 col-sm-12 col-lg-4'>
                  <img src={finance} alt='finance' className='comm-img'/>
            </div>
       </div>
        <div className='row'>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={music} alt='music' className='comm-img'/>
              </div>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={growth} alt='growth' className='comm-img'/>
              </div>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={gaming} alt='gamin' className='comm-img'/>
              </div>
          </div>
          <div className='row'>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={coding} alt='coding' className='comm-img'/>
              </div>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={design} alt='design' className='comm-img'/>
              </div>
              <div className='col-md-4 col-sm-12 col-lg-4'>
                    <img src={web3} alt='web3' className='comm-img'/>
              </div>
          </div>
          <div className='row text-center' style={{
            width: '100%',
            marginTop: '3rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}>
                  <h3 style={{
                        fontWeight: '600',
                        lineHeight: '1.5',
                  }}>
                        Be a part of our exclusive clubs at MancMint and grow.<br/>
                        Upskill withing the community by interacting and finding<br/>
                        payable clients!
                  </h3>
                  <div id="line-x"/>
                  <h4  style={{
                        fontWeight: '600',
                        lineHeight: '1.5',
                        marginBottom: '3rem',
                  
                  }}>
                  Be a part just by Signing up
                  </h4>
            </div>

    </div>
  )
}

export default CommunityBox;
