import React            from 'react';
import Sidebar          from '../components/Sidebar/Sidebar';

// import './ChatPage.css';
import GreetHeader from '../components/GreetHeader/GreetHeader';
import CommunityChat from '../components/CommunityChat';

function CommunityChatPage() {
  return (
    <div className='chat-page'>
        <div>
            <Sidebar/>
        </div>
        <div className='chat-container'>
            <GreetHeader greetText='Communities'/>
            <div className='chat-component'>
                <CommunityChat/>
            </div>
        </div>
    </div>
  )
}

export default CommunityChatPage;
