import { createContext, useState } from 'react';

export const intialState = {
    communities: null,
    isCommunitiesLoading: true,

    isCommunityChatOpen: false,
    openedCommunityChat: {
        community: null,
        messages: null,
        isMessagesLoading: false,
        socket: null,
    },
    isMiniWindow: window.innerWidth <= 1050,
}

const CommunityChatContext = createContext([{}, () => { }]);

const CommunityChatProvider = (props) => {
    const [state, setState] = useState(intialState);
    return (
        <CommunityChatContext.Provider value = {[state, setState]}>
            { props.children }
        </CommunityChatContext.Provider>
    )
}

export { CommunityChatContext, CommunityChatProvider };
