import React, { useContext,useEffect,useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";

import './App.css';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Pricingknowmore from './pages/Pricingknowmore';
import Community from './pages/Community';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import ChatPage from './pages/ChatPage';
import Analytics from './pages/Analytics/Analytics';
import Hire from './pages/Hire/Hire';
import Profile from './pages/Profile/profile';
import CommunityChatPage from './pages/CommunityChatPage';
import AuthState from './Context/Auth/AuthState';
import AuthContext from './Context/Auth/AuthContext';
import Protected from './utils/Protected';
import Signupsurvey from './pages/Signupsurvey';
import OtherProfile from './pages/Profile/OtherProfile';
import PwdReset from './pages/PwdReset';
import ForgotPass from './pages/ForgotPass';
import Cohort from './pages/Cohort';



function App() {
    
  return (
    <AuthState>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} exact />
          <Route path="/pricing" element={<Pricing/>} exact />
          <Route path="/pricing-knowmore" element={<Pricingknowmore/>} exact />
          <Route path='/blog' element={<Cohort/>} exact/>
          <Route path='/community' element={<Community/>} exact/>
          <Route path='/signin' element={<Signin/>} exact/>
          <Route path='/signup' element={<Signup/>} exact/>
          <Route path='/signup/survey' element={<Signupsurvey/>} exact/>
          <Route path="/password_reset/confirm/:token" element={<PwdReset/>} exact />
          <Route path="/forgot-password" element={<ForgotPass/>} exact />
          <Route path='/dashboard' element={
            <Protected >
              <Dashboard/>
            </Protected>
          } exact/>
          <Route path='/dashboard/analytics' element={
            <Protected >
              <Analytics/>
            </Protected>
          } exact/>
          <Route path='/dashboard/hire' element={
            <Protected >
              <Hire/>
            </Protected>
          } exact/>
          <Route path='/dashboard/chats' element={
            <Protected >
              <ChatPage/>
            </Protected>
          } exact/>
          <Route path='/dashboard/chats/:id' element={
            <Protected >
              <ChatPage/>
            </Protected>
          } exact/>
          <Route path='/dashboard/profile' element={
            <Protected >
              <Profile/>
            </Protected>
          } exact/>
          <Route path='/dashboard/profile/:username' element={
            <Protected >
              <OtherProfile/>
            </Protected>
          } exact/>

          <Route path='/dashboard/communities' element={
            <Protected >
              <CommunityChatPage/>
            </Protected>
          } exact/>
        </Routes>
      </BrowserRouter>
      {/* <VercelAnalytics /> */}
    </AuthState>
  );
}

export default App;
