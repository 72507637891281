import React from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Hero from '../components/Hero/Hero';
import Creators from '../components/Creator/Creators';
import About from '../components/About/About';
import Whyus from '../components/Whyus/Whyus';
import Join from '../components/Join/Join';
import Footer from '../components/Footer/Footer';
import Faqs from '../components/Faqs/Faqs';
import UpWorkshop from '../components/UpWorkshop/UpWorkshop';
import Confetti from 'react-confetti';
function Home() {
  const width = window.innerWidth - 20;
  let height = window.innerHeight - 280;
  if(window.innerWidth < 768){
      height = window.innerHeight - 780;
  }

  return (
    <div>
        <HomeNav/>
        <Hero/>
        <Creators/>
        <About/>
        <Whyus/>
        <div style={{ position: 'relative' }}>
            <Confetti  width={width} height={height} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }} run={true}/>
            <UpWorkshop/>
        </div>
        <Faqs/>
        <Join/>
        <Footer/>
    </div>
  )
}

export default Home;
