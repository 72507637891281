import {React,useState,useEffect} from 'react';
import './HomeChats.css';
import online from './online.png';
import offline from './offline.png';
import { Link, useNavigate } from "react-router-dom";

function HomeChats({profilePic,name,lastMessage,timestamp,activeStatus}) {
  const navigate = useNavigate();
    const [hoursAgo, setHoursAgo] = useState(null);
    useEffect(() => {
      const currentTimestamp = new Date();
      const targetTimestamp = new Date(timestamp);
      const timeDifference = currentTimestamp - targetTimestamp;
      const hoursAgoValue = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60)));
      setHoursAgo(hoursAgoValue);
    }, [timestamp]);
  return (
    <div className='chat-row'   onClick={
        () => {
            navigate('/dashboard/chats');
        }
    }>
        <div className='img-box'>
            <img src={profilePic ? profilePic : 'https://cdn-icons-png.flaticon.com/256/3135/3135823.png'} alt='profile-pic' className='profile-pic-home-chat' />
            <img src={activeStatus ? online : offline} alt='active-status' className='active-status-img' />
        </div>
        <div className='chat-details'>
            <div className='chat-name'>{name}</div>
            <div className='chat-last-message'>{lastMessage}</div>
        </div>
        <div className='chat-timestamp'>
            <p
              className='chat-timestamp-text'
            >
                {hoursAgo}h
            </p>
        </div>
    </div>
  )
}

export default HomeChats;