import CommunityBadge from "./CommunityBadge";
import { ThreeDots } from 'react-loader-spinner';

export default function CommunitiesContainer({ communities, isCommunitiesLoading = true, onCommunityChatOpen, openedCommunityChat }) {
  if (isCommunitiesLoading) 
    return (
      <div
        className='communities_container'
        id='communities_container_div'
      >
        <div className='list_of_communities d_flex flex_column'
        // style={{
        //   maxWidth: "15rem",
        //   borderRight: "1px solid #f0f0f0",
        // }}
        >
          <ThreeDots
                      visible={true}
                      height="60"
                      width="60"
                      color="#1594D4"
                      radius="5"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
          />
        </div>
      </div>
    )

  return (
    <div
      className='communities_container'
      id='communities_container_div'
    >
      <div className='list_of_communities d_flex flex_column'
      // style={{
      //   maxWidth: "15rem",
      //   borderRight: "1px solid #f0f0f0",
      // }}
      >
        {communities.length === 0
          ? <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "59vh",
                padding: "0.5rem 1rem",
              }}
            >
              No communities available
            </span>
          : communities.map((community) => (
            <div 
              className={`community_list_item_container ${(openedCommunityChat?.community?.community_id === community.community_id) ? 'chat-list-item-selected': ''}`}
              key={community.community_id}
            >
              <CommunityBadge
                key={community.community_id}
                src={community?.logo}
                title={community.name}
                onClick={() => onCommunityChatOpen(community)}
                isActive={openedCommunityChat?.community?.community_id === community?.community_id}
              />
            </div>
        ))}
      </div>
    </div>
  )
}