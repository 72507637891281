
// example item
// {
//     "user_id": 3,
//     "username": "test_user1",
//     "first_name": "test",
//     "last_name": "user1",
//     "profile_pic": "",
//     "last_message": {
//         "id": 7,
//         "sender": 3,
//         "receiver": 2,
//         "group": null,
//         "content": "kashf",
//         "timestamp": "2024-02-08T17:17:27.996877Z",
//         "is_read": false
//     }
// },

import profileAvatar from './profileAvatar.png';

export function ChatListItem({ user, onChatOpen }) {
    let shortTime = user.last_message.timestamp?.split("T")[1].split(".")[0].slice(0, -3);
    return (
    <div className='chat-list-item-container'>
        <div 
            key={user.user_id}
            data-sender-id={user.user_id}
            id={"chat-list-item-sender-id-" + user.user_id}
            className='chat-list-item' 
            onClick={() => onChatOpen(user)}
        >
        <div className='chat-list-item-image'>
            <img src={user.profile_pic || profileAvatar } alt='user'/>
        </div>
        <div className='chat-list-item-details'>
            <h5 className='chat-list-item-name'>
            {user?.username.substring(0, 14) + (user.username.length > 14 ? '...' : '')}
            </h5>
            <p className='chat-list-item-message'>
            {user.last_message.content.substring(0, 40) + (user.last_message.content.length > 40 ? '...' : '')}
            </p>
        </div>
        <div className='chat-list-item-time'>
            <p>{shortTime}</p>
        </div>
        </div>
    </div>
    )
}