import { useContext } from "react";
import { ChatContext } from "./ChatContext";
import authContext from "../../Context/Auth/AuthContext";

export const ChatHook = () => {
  const [state, setState] = useContext(ChatContext);
  const { userData, apiCall } = useContext(authContext);
  const socket_URL = `${process.env.REACT_APP_BACKEND_URL.replace(
    "http",
    "ws"
  )}/ws/chat`;

  const updateState = async (updatedObj) => {
    await setState((stateObj) => ({ ...stateObj, ...updatedObj }));
  };

  const fetchChatsList = async (isIntialCall = true) => {
    await updateState({ isChatsListLoading: isIntialCall });
    const { res, err } = await apiCall("/api/chats", "get");
    if (err) {
      
      return;
    }

    let unreadChatsCount = 0;
    res.forEach((chat) => {
      unreadChatsCount += chat.last_message.is_read === false;
    });

    // close the socket if it exists
    state?.openedUserChat?.socket?.close();

    await updateState({
      isChatsListLoading: false,
      chatsList: res,
      unreadChatsCount,
    });
  };

  const handleResize = () => {
    // handles the show and close of list of chats sidebar and messages tab
    try {
      if (state.isMiniWindow) {
        if (state.isChatOpen) {
          document.getElementById("chats-container-div").style.display = "none";
          document.getElementById("chat-content-div").style.display = "block";
        } else {
          document.getElementById("chats-container-div").style.display =
            "block";
          document.getElementById("chat-content-div").style.display = "none";
        }
        document.getElementById("chats-container-div").style.width = "100%";
        document.getElementById("chat-content-div").style.width = "100%";
      } else {
        document.getElementById("chats-container-div").style.width = "30%";
        document.getElementById("chat-content-div").style.width = "70%";
        // document.getElementById('chat-content-div').style.display = 'block';
      }
    } catch (error) {
      
    }
  };

  const handleSocketOpen = async (event) => {
    await setState((stateObj) => {
      return {
        ...stateObj,
        openedUserChat: {
          ...stateObj.openedUserChat,
          isMessagesLoading: false,
        },
      };
    });
  };

  const handleSocketClose = (event) => {
    
  };

  const handleMessageSend = async (messageContent) => {
    
    if (!state.openedUserChat.socket) {
      alert("unable to send message, please reload and try again");
      return;
    }
    let newMessage = {
      content: messageContent,
      is_read: false,
      timestamp: `T${new Date().toString().substr(16, 8)}`,
      sender: userData.user_id,
      receiver: state.openedUserChat.user.user_id,
    }
    await setState((stateObj) => {
      let chatsList = stateObj.chatsList;
      let user_index = chatsList.findIndex(
        (chat) =>
          chat.user_id === newMessage.sender ||
          chat.user_id === newMessage.receiver
      );
      let user = chatsList.splice(user_index, 1)[0];
      user.last_message = newMessage;

      return {
        ...stateObj,
        chatsList: [user, ...chatsList],
        openedUserChat: {
          ...stateObj.openedUserChat,
          messages: [...stateObj.openedUserChat.messages, newMessage],
        },
      };
    });
    await state.openedUserChat.socket.send(
      JSON.stringify({
        content: messageContent,
        sender: userData.user_id,
        receiver: state.openedUserChat.user.user_id,
      })
    );
    
  };

  const handleChatOpen = async (user) => {
    // fetches the messages of user
    const previouslyOpendedChatUserId = state.openedUserChat.user?.user_id,
      currentlyOpeningChatUserId = user.user_id;
    if (state.isChatOpen) {
      if (previouslyOpendedChatUserId === currentlyOpeningChatUserId) return;
      else {
        document
          .getElementById(
            "chat-list-item-sender-id-" + previouslyOpendedChatUserId
          )
          .classList.remove("chat-list-item-selected");
        await updateState({
          isChatOpen: false,
          openedUserChat: {
            user: null,
            messages: null,
            isMessagesLoading: false,
          },
        });
      }
    }

    await updateState({
      isChatOpen: true,
      openedUserChat: {
        user: user,
        messages: null,
        isMessagesLoading: true,
      },
    });

    document
      .getElementById("chat-list-item-sender-id-" + currentlyOpeningChatUserId)
      .classList.add("chat-list-item-selected");
      
    const { res, err } = await apiCall(
      `/api/chats/${currentlyOpeningChatUserId}`,
      "get"
    );
    

    let x = user.user_id,
      y = userData.user_id;
    if (x > y) {
      let temp = x;
      x = y;
      y = temp;
    }

    let socket = new WebSocket(`${socket_URL}/${x}-${y}/`);

    socket.addEventListener("open", handleSocketOpen);
    socket.addEventListener("close", handleSocketClose);
    socket.addEventListener("message", handleSocketMessage);

    await updateState({
      openedUserChat: {
        user: user,
        messages: res,
        isMessagesLoading: true,
        socket,
      },
    });
  };

  const handleChatClose = async () => {
    // closes the messages of user
    try {
      document
        .getElementById(
          "chat-list-item-sender-id-" + state.openedUserChat.user.user_id
        )
        .classList.remove("chat-list-item-selected");
    } catch (error) {
      
    }

    state.openedUserChat.socket.close();

    await updateState({
      isChatOpen: false,
      openedUserChat: {
        user: null,
        messages: null,
        isMessagesLoading: false,
        socket: null,
      },
    });
  };

  const handleSocketMessage = async (event) => {
    let newMessage = JSON.parse(event.data);
    if (newMessage.sender === userData.user_id) return;
    await setState((stateObj) => {
      let isMessageExist = stateObj.openedUserChat.messages?.find(
        (message) => newMessage.id === message.id
      );
      if (isMessageExist) return stateObj;

      
      let chatsList = stateObj.chatsList;
      let user_index = chatsList.findIndex(
        (chat) =>
          chat.user_id === newMessage.sender ||
          chat.user_id === newMessage.receiver
      );
      let user = chatsList.splice(user_index, 1)[0];
      user.last_message = newMessage;

      return {
        ...stateObj,
        chatsList: [user, ...chatsList],
        openedUserChat: {
          ...stateObj.openedUserChat,
          messages: [...stateObj.openedUserChat.messages, newMessage],
        },
      };
    });
    
  };

  return {
    state: state,
    fetchChatsList,
    updateState,
    handleResize,
    handleChatOpen,
    handleChatClose,

    handleMessageSend,
  };
};
