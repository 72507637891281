import React, { useContext, useState, useEffect } from "react";
import "./Sidebar.css";
import {
  FaChartLine,
  FaChartBar,
  FaRegCompass,
  FaWallet,
  FaBusinessTime,
} from "react-icons/fa";
import navlogo from "./navlogo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
// import { HiOutlineDocumentText } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { CgArrowsV } from "react-icons/cg";
import { LuChevronsUpDown } from "react-icons/lu";
import { LuChevronsDownUp } from "react-icons/lu";
import overviewIcon from "./overviewIcon.svg";
import AuthContext from "../../Context/Auth/AuthContext";
// import ProfilePic from './profile_pic.png';

const Sidebar = (props) => {
  const {userData, loading,getUserData} = useContext(AuthContext);
  useEffect(() => {
    if (!userData || Object.keys(userData).length === 0) {
      getUserData();
    }
    
  }
  ,[]);

  const [openUserActions, SetUserActions] = useState(false);
  const[mouseAction,SetMouseAction]=useState(false);
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const handleclick = () => {
    setshow(!show);
  };
  // Log out
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };
  const movehome = () => {
    navigate("/");
  };

  return (
    <>
      {" "}
      <div id="dmobile" onClick={handleclick}>
        <i id="bar" className={show ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <nav className={show ? "navbar2 nav-set" : "navbar2"}>
        <section style={{ height: "80vh", width: "100%" }}>
          <div className="navbar2__logo" style={{ cursor: "pointer" }}>
            <img src={navlogo} onClick={movehome} alt="Logo" />
            <h1 className="navbar2_head">MancMint</h1>
          </div>
          <ul className="navbar2__links nav-set">
            <li
                className={
                    window.location.pathname === "/dashboard" ? "active" : ""
                }
            >
              <Link to="/dashboard" onClick={handleclick}>
                {/* <FaChartLine /> */}
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24481 15.6541L10.2379 11.7639L13.6521 14.4458L16.5812 10.6655" stroke="#A4A5A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="19.9954" cy="5.07308" r="1.9222" stroke="#A4A5A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.9245 3.99316H7.65673C4.64529 3.99316 2.77802 6.12589 2.77802 9.13733V17.2197C2.77802 20.2312 4.60868 22.3547 7.65673 22.3547H16.2609C19.2723 22.3547 21.1396 20.2312 21.1396 17.2197V10.1808" stroke="#A4A5A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Overview
              </Link>
            </li>
            {/* <li>
              <Link to="/dashboard/payments" onClick={handleclick}>
                <FaWallet />
                Payments
              </Link>
            </li> */}
            <li  
              className={
                window.location.pathname === "/dashboard/analytics" ? "active" : ""
            }
            >
              <Link to="/dashboard/analytics" onClick={handleclick}>
                <FaChartBar />
                Analytics
              </Link>
            </li>
            <li
                className={
                    window.location.pathname === "/dashboard/chats" ? "active" : ""
                }
            >
              <Link to="/dashboard/chats" onClick={handleclick}>
                <FaRegCompass />
                Chat
              </Link>
            </li>

            <li
                className={
                    window.location.pathname === "/dashboard/hire" ? "active" : ""
                }
            >
              <Link to="/dashboard/hire" onClick={handleclick}>
                <FaBusinessTime />
                Hire
              </Link>
            </li>
            <li
                className={
                    window.location.pathname === "/dashboard/communities" ? "active" : ""
                }
            >
              <Link to="/dashboard/communities" onClick={handleclick}>
                <FaRegCompass />
                Communities
              </Link>
            </li>
            <li
                className={
                    window.location.pathname === "/dashboard/profile" ? "active" : ""
                }
            >
              <Link to="/dashboard/profile" onClick={handleclick}>
                <IoSettingsOutline />
                Profile
              </Link>
            </li>
            {/* <li>
              <Link to="/dashboard/settings" onClick={handleclick}>
                <IoSettingsOutline />
                Settings
              </Link>
            </li> */}
           
          </ul>
        </section>
        <section
          style={{ height: "15vh", display: "flex", alignItems: "center" }}
          className="user_action_section nav-set"
        >
          <div
            className="user_action"
            onClick={() => {
              SetUserActions(!openUserActions);
            }}
          >
            <img
              src={userData.profile_pic || '/images/profile_pic.png'}
              alt="Profile"
              height={40}
              width={40}
              style={{
                borderRadius: "50%",

              }}
            />
            <div>
              <span style={{ fontSize: "14px" }}>{userData.first_name}</span>
              <span style={{ color: "gray", fontSize: "11px" }}>
                Creator account
              </span>
            </div>
            {
              openUserActions ? (
                <LuChevronsDownUp/>
              ) : (
                <LuChevronsUpDown/>
              )
            }
            {/* <CgArrowsV/> */}
          </div>
          {openUserActions && (
            <div className="action_buttons" onClick={logout}   
            onMouseLeave={
                () => {
                    SetUserActions(!openUserActions);
                  }
            }
            >
              <ul style={{
                marginTop: "0px",
              }}>
                <li>
                  <MdOutlineLogout />
                  Log out
                </li>
              </ul>
            </div>
          )}
        </section>
      </nav>
    </>
  );
};

export default Sidebar;