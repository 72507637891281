import { CommunityChatHook } from './CommunityChatHook';
import CommunitiesContainer from './CommunitiesContainer';
import CommunityChatBox from './CommunityChatBox';
import { useEffect } from 'react';

const PlaceHolderChat = () => (
  <div className='chat_content' id='chat_content_div'>
    <div className='chat_content_info'>
      <h4>Select a community to start messaging</h4>
    </div>
  </div>
)

function CommunityChatBody({ currentlyOpenedCommunity = null }) {
  const {
    state,
    fetchCommunitiesList,
    handleCommunityChatOpen,
    handleCommunityChatClose,
    handleResize,

    handleMessageSend,
  } = CommunityChatHook();

  // fetches user chats
  useEffect(() => {
    fetchCommunitiesList();
  }, []);

  useEffect(() => {
    handleResize();
  }, [state.isCommunityChatOpen]);

  return (
    <div className='chat_component_parent' >
      <CommunitiesContainer
        communities={state.communities}
        isCommunitiesLoading={state.isCommunitiesLoading}
        onCommunityChatOpen={handleCommunityChatOpen}
        openedCommunityChat={state.openedCommunityChat}
      />
      {state.isCommunityChatOpen
        ? <CommunityChatBox 
            openedCommunityChat={state.openedCommunityChat} 
            onCommunityChatClose={handleCommunityChatClose} 
            handleMessageSend={handleMessageSend}
          />
        : <PlaceHolderChat />}
    </div>
  )
}

export default CommunityChatBody;
