import {React,useState,useContext,useEffect} from 'react';
import HomeNav from '../components/HomeNav/HomeNav';
import Footer from '../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner/Spinner';
import imglogin from './loginimg.png';
import view from './view-hide.png';
import './Signin.css';
import elbg from './el-bg.png';
import axios from 'axios';
import AuthContext from '../Context/Auth/AuthContext';
import { useParams } from 'react-router-dom';


function PwdReset() {
  let resetToken = useParams();
  const navigate = useNavigate();



    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setloading] = useState(false);
    const movesignup = () => {
        navigate('/signin');
    }
    const [credentials, setcredentials] = useState({ password: "", confirmPassword: "" })
    const onchange = (e) => {
        setcredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const backendurl = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        if(credentials.password !== credentials.confirmPassword){
            alert('Passwords do not match');
            setloading(false);
            return;
        }
        const payload = {
            password: credentials.password,
            token: resetToken.token
        }
        // console.log(payload);

        await axios.post(`${backendurl}/api/password_reset/confirm/`, payload).then(res => {
            if (res.status === 200) {
                alert("Password reset successful");
                window.location.href = '/signin';
            }
        }
        ).catch(err => {
            console.log(err);
            alert("Something went wrong");
        }
        );
    }

  return (
    <>
       <HomeNav/>
       <div className='signin-bx-1 fl-r'>
            <div className="signin-bx-2">
                <div className="signin-txt-bx ">
                    <div className="sign-in-txt-1">  Oh? <br /> Hello there</div>
                    <div className="sign-in-txt-2"> Remember your Password? <br/> Please  go back to <b onClick={movesignup}>Signin </b></div>
                </div>
                <div className="signin-img1"> <img alt=''  src={imglogin} /></div>
            </div>
            <div className="signin-bx-3">
                <div className="signin-signup">
                    <form action="" className="sign-in-form">
                        <div className="input-field">
                            <input type="password" name="password" value={credentials.password} placeholder="Enter new password" onChange={onchange} style={{
                                width: '19rem',
                            }}/>
                        </div>
                        <div className="input-field">
                            <input type="password" value={credentials.confirmPassword} name="confirmPassword" onChange={onchange} placeholder="Confirm new password" style={{
                                width: '19rem',
                            }} />
                        </div>
                        {loading ? <Spinner /> : <button onClick={handleSubmit}>Submit</button>}
                    </form></div>
            </div>
        </div>
       <Footer/>
    </>
  )
}

export default PwdReset;
