import {React,useState,useRef, useEffect, useContext} from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import SearchComp from '../../components/SearchComp/SearchComp';
import './Hire.css';
import Modal from 'react-bootstrap/Modal';
import JobForm from '../../components/Jobform/Jobform';
import axios from 'axios';
import authContext from '../../Context/Auth/AuthContext';
import Loader from '../../components/Loader/Loader';
import HireSearchComp from '../../components/SearchComp/HireSearchComp';
import '../../components/SearchComp/SearchComp.css';
import profileAvatar from './profileAvatar.png';

const HireBoxLabel = ({ bgcolor, logo, name }) => {
    const style = {
      backgroundColor: bgcolor || '#FFCA404D', // Default to an empty string if no bgcolor is provided
      color: 'black',
      margin:"0.3rem",
      padding: '0.2rem 0.5rem',
      borderRadius: '1rem',
      fontSize: '0.7rem',
      fontWeight: '500',
    };
  
    return (
      <p style={style} className='hire-label'>
        <img src={logo} className='img-fluid' alt='skill-logo' style={{
            width:"1rem",
            height:"1rem",
        }}/>
        {' '}
        {name}
      </p>
    );
  };

  const HireDesc = ({ selected_skills }) => (
    <div className='hire-box-desc'>
      {selected_skills.map((label, index) => (
        <HireBoxLabel
          key={index}
          bgcolor={label.bgcolor}
          logo={label.logo}
          name={label.name}
        />
      ))}
    </div>
  );

  const HireBox =({posted_by, title, selected_skills, application_link, employment_type, work_location }) =>{
    const [modified_skills, setModifiedSkills] = useState([]);

    const empskill = {
        name:employment_type,
        logo:"https://res.cloudinary.com/djof8rl5r/image/upload/v1/media/skill_logo/Location_lxokwq",
        bgcolor:"#FFCA404D",
    }

    const workskill = {
        name:work_location,
        logo:"https://res.cloudinary.com/djof8rl5r/image/upload/v1/media/skill_logo/Alarm_Clock_dmrhqk",
        bgcolor:"#40FF484D",
    }

    useEffect(() => {
        const newSkills = [...selected_skills];
        newSkills.unshift(empskill, workskill);
        setModifiedSkills(newSkills);
      }, [selected_skills, employment_type, work_location]);
    
    return(
    <>
        <div className='hirebox_header'>
            <img src={posted_by.profile_pic || profileAvatar} alt="hire-img" className="hire-pro-img" style={{
                borderRadius:"50%",
            }}/>
            <p className="hire-box-name">{posted_by.name}</p>
        </div>
        <h6 className='hire-box-title'>
            {title}
        </h6>
        <div className='hire-box-desc'>
            <HireDesc selected_skills={modified_skills} />
        </div>
        <div className='hire-box-apply'>
            <button className='hire-box-apply-btn' onClick={
                () => {
                    window.open(application_link, "_blank");
                }
            } target="_blank">Apply Now</button>
            <div id="hire-line"/> 
        </div>
    </>
  );
 }

function Hire() {
  const { hiringData, getHiringData, loading, setLoading } = useContext(authContext);
  const products = [];

  useEffect(() => {

    getHiringData();

  },[]);

  const [searchVal, setSearchVal] = useState("");

  const handleInput = (e) => {
    setSearchVal(e.target.value);
  };

  const filteredHiringData = hiringData.filter(data => 
    data.title.toLowerCase().includes(searchVal.toLowerCase())
  );


  const [modelShow, setModelShow] = useState(false);
  const handleClose = () => { setModelShow(false); getHiringData(); }; 
  const handleShow = () => setModelShow(true);

  const handleHireNow = () => {
    setModelShow(true);
  }

  return (
    <div className='hire-page'>
      <div>
        <Sidebar/>
      </div>
      {
          loading ? <Loader/>: <>
      <div className='hire-content'>
            <div className='hb1 container'>
                <div className='header_hire row'>
                    <div className='header_hire-left col-md-6 col-lg-6'>
                        <h1 className="welcome-text">Lets Get you Hired!</h1>
                        <p className="day-date">
                            {new Date().toLocaleDateString("en-US", {
                                weekday: "long",
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                            })}
                        </p>
                    </div>
                    <div className='header_hire-right col-md-6 col-lg-6'>
                    <div className="container">
                      <div className="input-wrap">
                        <button className="search-btn">
                          <i className="fas fa-search" />
                        </button>
                        <input
                          onChange={handleInput}
                          value={searchVal}
                          type="text"
                          name="product-search"
                          id="product-search"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                        <button className="btn hirenow-btn" onClick={handleHireNow}>Hire Now?</button>
                    </div>
                </div>
                <div className='hires'>
                    {filteredHiringData.length === 0
                      ? <p
                          style={{
                            textAlign: "center",
                            padding: "1rem",
                            fontSize: "1.5rem"
                          }}
                        >
                          No Job listings available
                        </p>
                      : filteredHiringData.reduce((pairs, data, index) => {
                    if (index % 2 === 0) {
                        pairs.push([data]);
                    } else {
                        pairs[pairs.length - 1].push(data);
                    }
                    return pairs;
                        }, []).map((pair, rowIndex) => (
                        <div key={rowIndex} className='row'>
                            {pair.map((data, colIndex) => (
                            <div key={colIndex} className='col-md-4 col-lg-4 hire-box'>
                                <HireBox {...data} />
                            </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
      </div>
            <Modal
                    show={modelShow}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    animation={false}
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Post a Gig!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <JobForm/>
                    </Modal.Body>
            </Modal>
      </>
      }
    </div>
  )
}

export default Hire;
