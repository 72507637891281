import {React,useEffect,useState,useContext} from 'react';
import './Analytics.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import SearchComp from '../../components/SearchComp/SearchComp';
import {
    AreaChart,
    XAxis,
    YAxis,
    Area,
    CartesianGrid,
    Tooltip,
    Bar,
    BarChart,
    ResponsiveContainer
  } from "recharts";
import mintVol from './mint-vol.png';
import intNetwork from './intNetwork.png';
import intChats from './intChats.png';
import intDiscussions from './intDiscussions.png';
import intGroups from './intGroups.png';
import clock from './clock.png';
import bitcoin from './bitcoin.png';
import bitcoinbag from './bitcoinbag.png';
import graphh from './graphh.png';
import nft from './nft.png';
import timeAnalysis from './time-analysis.png';
import uparrow from './uparrow.png';
import group1 from './group1.png';
import group2 from './group2.png';
import axios  from 'axios';
import AuthContext from "../../Context/Auth/AuthContext";
import Loader from '../../components/Loader/Loader';

function Analytics() {
  const[showAll, setShowAll] = useState(false);
  const handleKnowMore = () => {
    setShowAll(!showAll);
  }   
  const products = [];

  const [interactionsData, setInteractionsData] = useState({});
  const interactions =[
    {
        month: "Jan",
        Interactions: 30,
    },
    {
        month: "Feb",
        Interactions: 35,
    },
    {
        month: "Mar",
        Interactions: 20,
    },
    {
        month: "Apr",
        Interactions: 40,
    },
    {
        month: "May",
        Interactions: 50,
    },
    {
        month: "Jun",
        Interactions: 60,
    },
    {
        month: "Jul",
        Interactions: 70,
    },
    {
        month: "Aug",
        Interactions: 80,
    },
  ]

  const {userData, loading, setLoading,setUserData, apiCall } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const BACKENDURL = process.env.REACT_APP_BACKEND_URL;
    axios.get(`${BACKENDURL}/api/dashboard/analytics/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
        })
        .then(response => {
            const data = response.data;
            
            setLoading(false);
            setInteractionsData(data);
        })
        .catch(error => {
            
        });
  }, []);


  const handleCourseBoxClick = () => {
    alert('Coming soon!');
  };

  return (
    <div className='analytics-page'>
      <div>
          <Sidebar/>
      </div>
      {
        loading ? <Loader/> :(
            <div className='analytics-content'>
        <div className='a1 container'>
            <div className='header_analytics row'>
                <div className='header_analytics-left col-md-8 col-lg-8'>
                    <h1 className="welcome-text">Analytics</h1>
                    <p className="day-date">
                        {new Date().toLocaleDateString("en-US", {
                            weekday: "long",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                        })}
                    </p>
                </div>
                <div className='header_analytics-right col-md-4 col-lg-4'>
                    <SearchComp products={products}/>
                </div>
            </div>
            <div className='a1c1'>
                <div className="interactions-box">
                    <div className="row row-p">
                        <div className='col-md-6 col-lg-6'>
                            <h1 className='interactions-title'>
                                Your Interaction
                            </h1>
                            <h1 className='total-interactions'>
                                {interactionsData.no_of_messages}
                            </h1>
                            <div className='interaction-desc'>
                                <img src={mintVol} alt="mint-vol" className='mint-vol'/>
                                <p className='interactions-text'>
                                Mint discussions <br/> this month
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6'>
                            <div className="int-chart">
                            <ResponsiveContainer width={"100%"} aspect={1.8}>
                            <BarChart
                                fontSize={10}
                                data={interactions}
                                margin={{ top: 15, bottom: 0, left: -40 }}
                            >
                                <CartesianGrid vertical={false} horizontal={false}/>
                                <XAxis dataKey="month" hide={true}/>
                                <YAxis axisLine={false} tick={{fill: '#fff'}} tickLine={false}/>
                                <Tooltip contentStyle={{
                                    color: '#fff',
                                    backgroundColor: '#000',
                                    borderRadius: '10px',
                                    fontSize: '12px',
                                }}/>
                                <Bar dataKey="Interactions" fill="#fff" barSize={2}/>
                            </BarChart>
                            </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div className='int-cont row'>
                            <div className='col-md-3 int-cont-col'>
                                <p className='int-heading'>
                                    <img src={intNetwork} alt="int-network" className='int-network'/>
                                    {' '}Your Network
                                </p>
                                <h1 className='count-detail'>
                                    {interactionsData.no_of_network}
                                </h1>
                                <div className='progress'>
                                    <div className='progress-bar' style={{width: `${(interactionsData.no_of_network / 100) * 100}%`, backgroundColor:"#FFA740"}}></div>
                                </div>
                            </div>
                            <div className='col-md-3 int-cont-col'>
                                <p className='int-heading'>
                                    <img src={intGroups} alt="int-network" className='int-network'/>
                                    {' '}No. of Groups
                                </p>
                                <h1 className='count-detail'>
                                    {interactionsData.no_of_joined_groups}+
                                </h1>
                                <div className='progress'>
                                    <div className='progress-bar' style={{width: `${(interactionsData.no_of_joined_groups / 100) * 100}%`, backgroundColor:"#FF3553"}}></div>
                                </div>
                               
                            </div>
                            <div className='col-md-3 int-cont-col'>
                                <p className='int-heading'>
                                    <img src={intDiscussions} alt="int-network" className='int-network'/>
                                    {' '}Discussions
                                </p>
                                <h1 className='count-detail'>
                                    {interactionsData.no_of_discussions}
                                </h1>
                                <div className='progress'>
                                    <div className='progress-bar' style={{width: `${(interactionsData.no_of_discussions / 100) * 100}%`, backgroundColor:"#1594D4"}}></div>
                                </div>
                            </div>
                            <div className='col-md-3 int-cont-col' style={{borderRight:"none"}}>
                                <p className='int-heading'>
                                    <img src={intChats} alt="int-network" className='int-network'/>
                                    {' '}Chats
                                </p>
                                <h1 className='count-detail'>
                                    {interactionsData.no_of_chats}
                                </h1>
                                <div className='progress'>
                                    <div className='progress-bar' style={{width: `${(interactionsData.no_of_chats / 100) * 100}%`, backgroundColor:"#66EF63"}}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> 
            </div>
            <div className='a1c2'>
            <div className='courses-box'>
                    <div className='row cou-row'>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={bitcoin} alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '100%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={timeAnalysis} alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Group</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Crypto Mausam
                                </h6>
                                <div className='duration_course'>
                                    <img src={group1} className='img-fluid' style={{
                                        height:"1.5rem"
                                    }}/>
                                </div>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row cou-row'>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={bitcoinbag} alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Group</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                0 to Hero shares tips
                                </h6>
                                <div className='duration_course'>
                                    <img src={group2} className='img-fluid' style={{
                                        height:"1.5rem"
                                    }}/>
                                </div>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={graphh} alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Bulls and bears
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}12 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '50%', backgroundColor:"#66B85E"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row cou-row'>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={nft} alt="course-img" className='course-imgg' style={{
                                    marginRight:"2rem"
                                }}/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Workshop</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Sell NFTs in 1 click
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}1.2 Hours
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '50%', backgroundColor:"#FFA740"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 course-box' onClick={handleCourseBoxClick}>
                            <div className='course-img'>
                                <img src={uparrow} alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Workshop</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                How to catch the trend
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}2.4 Hours
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '50%', backgroundColor:"#1C92CD"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showAll ? (
                    <>
                        <div className='courses-box'>
                    <div className='row'>
                        <div className='col-md-5 col-lg-5 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-5 col-lg-5 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6 course-box'>
                            <div className='course-img'>
                                <img src="https://mancmint.com/static/media/bit%20coin%20with%20earth.b88deb19b225e97241fc.png" alt="course-img" className='course-imgg'/>
                            </div>
                            <div className='course-detail'>
                                <div className='course-header'>
                                    <p className='course-type'>Course</p>
                                    <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <h6 className='course-title'>
                                Find top cryptos to invest in
                                </h6>
                                <p className='duration_course'>
                                    <img src={clock} className='img-fluid'/>{' '}5 Episodes
                                </p>
                                <div className='progress' style={{
                                    marginBottom:"1rem",
                                    height:"5px",
                                    marginTop:"1rem",
                                }}>
                                    <div className='progress-bar' style={{width: '30%', backgroundColor:"#FF3553"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                    ) : null
                }
                <div className='row' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <button className='btn km-b' onClick={handleKnowMore} disabled={true}>
                        {showAll ? 'Show Less' : 'Load More'}
                        </button>
                </div>
        </div>
        </div>
      </div>
        )
      }
    </div>
  )
}

export default Analytics;
