import { useContext, useDeferredValue, useState } from "react";
import { Modal } from "react-bootstrap";
import authContext from "../../Context/Auth/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import profileAvatar from './profileAvatar.png';

const UserItem = ({user}) => {
  return (
    <div className="d-flex flex-row mt-1 justify-content-between align-items-center">
      <div className="p-1 d-flex flex-row mr-auto">
        <img
          src={user.profile_pic ? user.profile_pic: profileAvatar}
          alt="profile-pic"
          className="profile-pic-home-chat m-1"
        />
        <div className="px-2">
          <p 
            className="my-auto d-flex align-items-center"
            style={{
              fontSize: '0.8rem',
              fontWeight: '600',
              height: '100%',
            }}>
              {user.username}
          </p>
        </div>
      </div>
      <Link to={`/dashboard/profile/${user.username}`} style={{ textDecoration: 'none' }} >
        View Profile
      </Link>
    </div>
  );
};
export default function SearchModal({
  isModelOpen = false,
  setModalState,
  searchText,
  setSearchText,
}) {
  const { apiCall } = useContext(authContext);
  ;
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(null);

  const getSearchResults = async (e) => {
    setIsSearching(true);
    e.preventDefault();
    const { res, err } = await apiCall(`/api/search/${searchText}`);
    res && res.length === 0 && alert(`No users found with username ${searchText}`);
    res && setSearchResults(res);
    setIsSearching(false);
  };
  return (
    <Modal
      show={isModelOpen}
      onHide={() => setModalState(false)}
      backdrop="static"
      keyboard={true}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: "1.2rem",
          }}
        >
          Search
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={getSearchResults} className="mx-3 gap-1 d-flex align-items-center justify-content-center my-auto">
          <input
            onChange={(e) => { setSearchText(e.target.value); setSearchResults([]); }}
            value={searchText}
            className="border btn"
            type="text"
            name="q"
            id="q"
            placeholder="Search"
            style={{
              height: '38px',
              width: '100%',
              borderRadius: '5px',
            }}
            disabled={isSearching}
          />
          <button
            className="btn mb-2"
            style={{
              backgroundColor: "#1594D4",
              color: "#fff",
              fontWeight: "400",
            }}
            type="submit"
          >
            {isSearching ? "Searching" : "Search"}
          </button>
        </form>
        <div className="mx-3">
          {searchResults.length > 0 && searchResults.map((user) => (<UserItem key={user.id} user={user} />))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
