import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Profile.css";
import SearchComp from '../../components/SearchComp/SearchComp';
import Modal from 'react-bootstrap/Modal';
import AuthContext from "../../Context/Auth/AuthContext";
import axios from 'axios';
import ModalBody from "react-bootstrap/esm/ModalBody";
import { useParams } from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
import InstaIcon from './insta_icon.png';
import TwitterIcon from './twitter_icon.png';
import LinkedInIcon from './linkedin_icon.png';


function OtherProfile(props) {
  let {username} = useParams();

  const [skillModalShow, setSkillModalShow] = useState(false);
  const handleSkillModalClose = () => setSkillModalShow(false);

  const [communityModalShow, setCommunityModalShow] = useState(false);
  const handleCommunityModalClose = () => setCommunityModalShow(false);

  const [portfolioModalShow, setPortfolioModalShow] = useState(false);
  const handlePortfolioModalClose = () => setPortfolioModalShow(false);

  const {userData, loading, setLoading,setUserData} = useContext(AuthContext);

  const [currUserData, setCurrUserData] = useState({});
  const [currUserSkills, setCurrUserSkills] = useState([]);
  const [currUserCommunities, setCurrUserCommunities] = useState([]);
  const [currUserProjects, setCurrUserProjects] = useState([]);
  const [currUserPortfolio, setCurrUserPortfolio] = useState([]);
  const [currUserIsFollowing, setCurrUserIsFollowing] = useState(false);

  const handleFollow = async () => {
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    if (userToken){
        try {
            const res = await axios.post(backendURL+'/api/followers/',{
                follow:username,
                status:"true",
            },{
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            //
            alert("Followed successfully");
            setCurrUserIsFollowing(true);
        } catch (error) {
            
        }
    }
 }

  const handleUnfollow = async () => {
    const userToken = localStorage.getItem('token');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    if (userToken){
        try {
            const res = await axios.post(backendURL+'/api/followers/',{
                follow:username,
                status:"false",
            },{
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            //
            alert("Unfollowed successfully");
            setCurrUserIsFollowing(false);
        } catch (error) {
            
        }
    }
}


  useEffect(() => {
    const fetchData = async () => {
      
      setLoading(true);
      const userToken = localStorage.getItem('token');
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      if (userToken){
        try {
          const res = await axios.get(backendURL+'/api/profile/'+username,{
            headers: {
              'Authorization': `Bearer ${userToken}`
            }
          });
            setCurrUserData(res.data);
            setCurrUserSkills(res.data.skills);
            setCurrUserCommunities(res.data.communities);
            setCurrUserProjects(res.data.projects);
            setCurrUserPortfolio(res.data.portfolio);
            setCurrUserIsFollowing(res.data.is_following);
            if(currUserData){
              setLoading(false);
            }

        } catch (error) {
          
        }
      }
    }
    fetchData();
  }, [username]);
  


  const products = [
        "Product 1",
        "Product 2",
        "Product 3",
      ];
  const profileDetails = {
      name:"Harsha",
      username:"@harshavb08",
      about:"I am a full stack developer with 2 years of experience in web development. I have worked with React, NodeJs, Express and MongoDB. I am also a competitive programmer with 3 star rating on Codechef.",
      profilePic:"https://media.licdn.com/dms/image/D5603AQHxGLAnYmFp8g/profile-displayphoto-shrink_800_800/0/1695052708352?e=2147483647&v=beta&t=hHueBXCeQbSQnWAmOKuUTcbvOj9fHAmzmvdCMzMS9ic",
      coverPic:"https://img.lovepik.com/photo/20230421/medium/lovepik-colorful-vintage-floral-organic-background-photo-image_352306184.jpg",
      skills:[
        {
          name:"React",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
          score:70
        },
        {
          name:"React",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
          score:10
        },
        {
          name:"Communication skills",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
          score:10
        },
        {
          name:"Communication skills",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
          score:10
        }
      ],
      communitiesJoined:[
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        }
      ],
      socialNetworks:[
          {
              icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
              urladd:"https://www.linkedin.com/in/harsha-v-b-9a5a8a1a5/"
          },
          {
              icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
              urladd:"https://www.linkedin.com/in/harsha-v-b-9a5a8a1a5/"
          },
          {
            icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
            urladd:"https://www.linkedin.com/in/harsha-v-b-9a5a8a1a5/"
        },
      ],
      myPortfolio:[
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        },
        {
          name:"Finance Club",
          icon:"https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png",
        }
      ],
      myProjects:[
        "Project 1",
        "Project 2",
        "Project 3",
        "Project 4",
      ]
  };

  const reactIcon = "https://cdn.iconscout.com/icon/free/png-512/react-1-282599.png";

  return (
    <>
    <div className="profile-page">
      <div>
        <Sidebar/>
      </div>
      {
        loading ? <Loader/>:
         <>
            <div className='profile-content container'>
              <div className='pb1 container'>
                <div className='header_profile row'>
                        <div className='header_profile-left col-md-8 col-lg-8'>
                            <h1 className="welcome-text">Welcome {currUserData.first_name}</h1>
                            <p className="day-date">
                                {new Date().toLocaleDateString("en-US", {
                                    weekday: "long",
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                })}
                            </p>
                        </div>
                        <div className='header_profile-right col-md-4 col-lg-4'>
                            <SearchComp products={products}/>
                        </div>
                </div>
                <div className="profile-details">
                    <div className="cover-pic"
                        style={{
                          backgroundImage: `url(${currUserData.cover_pic || '/images/cover_pic.png'})`,
                          height: "30vh",
                          display:"flex",
                          alignItems:"flex-end",
                          justifyContent:"end"
                        }}
                    >
                    </div>
                    <div className="row" style={{marginBottom:"0.0rem"}}>
                        <div className="col-md-6 col-lg-6">
                            <div className="profile-d-div">
                                <img src={currUserData.profile_pic || '/images/profile_pic.png'} alt="profile-pic" className="pp-profile-pic"/>
                                <div className="profile-details">
                                  <h2 className="pp-profile-name">{currUserData.first_name}{' '}{currUserData.last_name}</h2>
                                  <p className="pp-profile-username">@{currUserData.username}</p>
                                  {
                                    currUserIsFollowing? (
                                        <button className="btn follow-btn" onClick={handleUnfollow}>Unfollow</button>

                                    ) : (
                                        <button className="btn follow-btn" onClick={handleFollow}>Follow</button>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pc-content">
                        <div className="col-md-9 col-lg-9 pc-content-left">
                          <div className="row">
                              <div className="col-md-6 col-lg-6 pc-content-about">
                                  <h6 style={{fontWeight:"700"}}>About:</h6>
                                  <p className="profile-about-text">{currUserData.about}</p>
                              </div>
                                <div className="col-md-5 col-lg-5 pc-content-skills">
                                    <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>My Skill Set:</h6>
                                    </div>
                                    <div className="">
                                      {currUserData && currUserSkills.slice(0, 3).map((skill)=>(
                                        <div className="row">
                                          <div className="skill-div">
                                            <div className="skill-in">
                                              <img src={skill.logo} alt="skill-icon" className="skill-icon"/>
                                              <p className="skill_name">{skill.name}</p>
                                            </div>
                                            <div className="p-bar">
                                              <div className='progress-bar-skill' style={{width: `${skill.score}%`, backgroundColor:"#FF3553"}}></div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    {
                                     currUserData && currUserSkills?.length > 3 ? (
                                          <div className="row text-center">
                                            <button className="btn viewmore-skills-btn"  onClick={() => {setSkillModalShow(true);}}>View More</button>
                                          </div>
                                      ) : null
                                    }
                                </div>
                          </div>
                          <div className="row">
                              <div className="col-md-5 col-lg-5 pc-content-socialnetwork">
                                  <h6 style={{fontWeight:"600"}}>My Social Network:</h6>
                                  <div className=" social-handles">
                                        <a href={`${currUserData.instagram_link}`} target="_blank" rel="noopener noreferrer" className="social-link">
                                          <img src={InstaIcon} alt="social-icon" className="social-icon"/>
                                        </a>
                                        <a href={`${currUserData.twitter_link}`} target="_blank" rel="noopener noreferrer" className="social-link">
                                          <img src={TwitterIcon} alt="social-icon" className="social-icon"/>
                                        </a>
                                        <a href={`${currUserData.linkedin_link}`} target="_blank" rel="noopener noreferrer" className="social-link">
                                          <img src={LinkedInIcon} alt="social-icon" className="social-icon"/>
                                        </a>
                                  </div>
                              </div>
                              <div className="col-md-5 col-lg-5 pc-content-portfolio">
                                    <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>My Portfolio:</h6>
                                    </div>
                                    <div className="">
                                      {currUserData && currUserPortfolio.slice(0, 3).map((portfolio) => (
                                          portfolio !== '' && (
                                            <div className="row">
                                              <div className="skill-div">
                                                <div className="skill-in">
                                                <i className="bi bi-person-vcard" style={{
                                                  marginRight:"0.5rem"
                                                }}/>
                                                  <a href={`https://${portfolio}`} target="_blank" className="skill_name" style={{
                                                    textDecoration:"none",
                                                    color:"#000"
                                                  }}>{portfolio}</a>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                      ))}
                                        {
                                        currUserData && currUserPortfolio?.length > 3 ? (
                                            <div className="row text-center">
                                              <button className="btn viewmore-skills-btn"  onClick={() => {setPortfolioModalShow(true);}}>View More</button>
                                            </div>
                                        ) : null
                                    }
                                    </div>
                              </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 pc-content-right">
                                <div className="" style={{
                                      display:"flex",
                                      justifyContent:"space-between"
                                    }}>
                                        <h6 style={{fontWeight:"700"}}>Personal Projects:</h6>
                                    </div>
                                <div className="personal-projects">
                                  {currUserData && currUserProjects.map((project)=>(
                                    <div className="row">
                                      <div className="skill-div">
                                        <div className="skill-in">
                                          {/* <p className="skill_name">{project}</p> */}
                                          {
                                            project.name!=="" && (
                                              <li>
                                                {project.name}
                                              </li>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            {/* Modals for profile sections */}
            {/* Modal for skills */}
            <Modal
                show={skillModalShow}
                onHide={handleSkillModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Skill Set:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                      {currUserSkills.map((skill)=>(
                        <div className="row">
                          <div className="skill-div">
                            <div className="skill-in">
                              <img src={skill.logo} alt="skill-icon" className="skill-icon"/>
                              <p className="skill_name">{skill.name}</p>
                            </div>
                            <div className="p-bar">
                              <div className='progress-bar-skill' style={{width: `${skill.score}%`, backgroundColor:"#FF3553"}}></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                </Modal.Body>
            </Modal>
            {/* Modal for communities */}
            <Modal
                show={communityModalShow}
                onHide={handleCommunityModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Communities:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {profileDetails.communitiesJoined.map((community)=>(
                      <div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <img src={community.icon} alt="skill-icon" className="skill-icon"/>
                            <p className="skill_name">{community.name}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
            </Modal>
            {/* Modal for portfolio */}
            <Modal
                show={portfolioModalShow}
                onHide={handlePortfolioModalClose}
                backdrop="static"
                keyboard={true}
                animation={false}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{
                    fontSize:"1.2rem"
                  }}>
                    My Portfolio:
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="">
                    {currUserPortfolio.map((portfolio)=>(
                      portfolio!=="" && (<div className="row">
                        <div className="skill-div">
                          <div className="skill-in">
                            <img src={reactIcon} alt="skill-icon" className="skill-icon"/>
                            <a href={`https://${portfolio}`} target="_blank" className="skill_name" style={{
                                                textDecoration:"none",
                                                color:"#000"
                                              }}>{portfolio}</a>
                          </div>
                        </div>
                      </div>)
                    ))}
                  </div>
                </Modal.Body>
            </Modal>
         </>
      }
    </div>
    </>
  )
}

export default OtherProfile;
